'use client';

import 'swiper/css/pagination';

import { cn } from '@common/utils';
import Image from 'next/image';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CustomLink } from '@/components/atom/CustomLink';
import { ROUTES } from '@/constants';

interface Card {
  image: number;
  href: string;
  target?: string;
}

const STATIC_BANNERS = {
  '사장님 카드': {
    image: 1,
    href: '/community/policyInfo/f75177be-4665-4cb8-b6c3-f23e71555322',
  },
  'K 패스': {
    image: 2,
    href: 'https://www.ibk.co.kr/cardbiz/detailBizNew.ibk?PDLN_CD=12&PDGR_CD=11&PDTM_CD=258&PDCD=0004&mz_cd=ADGROUN&ad_cd=wello',
    target: '_blank',
  },
  육아생활: {
    image: 3,
    href: ROUTES.RECOMMEND_POLICY_SITUATION_BABY_FORM.pathname,
  },
  디지털서비스개방만족도조사: {
    image: 4,
    href: 'http://survey.wowsurvey.com/mois',
    target: '_blank',
  },
} as const satisfies Record<string, Card>;

export type StaticBannerList = keyof typeof STATIC_BANNERS;

interface StaticBannerProps {
  bannerList: StaticBannerList[];
  className?: string;
}

export const StaticBanner = ({ bannerList, className }: StaticBannerProps) => {
  return (
    <section className={cn('mx-20 rounded-medium overflow-hidden', className)}>
      <Swiper
        grabCursor
        loop
        autoplay={{
          delay: 5_000,
          disableOnInteraction: false,
        }}
        className="relative"
        modules={[Pagination, Autoplay]}
        spaceBetween={8}
      >
        {bannerList.map((banner) => {
          const item = STATIC_BANNERS[banner];

          const target = 'target' in item ? item.target : undefined;

          return (
            <SwiperSlide key={banner}>
              <CustomLink aria-label={banner} href={item.href} target={target}>
                <Image
                  alt={banner}
                  height={203}
                  src={`/assets/pngs/banner/${item.image}.png`}
                  width={810}
                />
              </CustomLink>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
