import type { HTMLTagProps } from '@/types';

import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { cleanClassName } from '@common/utils';

import { MODAL_CLOSING_STATE, useModalClosing } from '@/hooks/useModalClosing';

import styles from './Modal.module.scss';

export interface ModalProps extends HTMLTagProps<'article'> {
  zIndex?: number;
  blurredBackground?: boolean;
  opened?: boolean;
  onClose?: () => void;
  backgroundScroll?: boolean;
}

/**
 *
 * @deprecated 공통 컴포넌트를 사용해주세요.
 */
export const Modal = ({
  //* Modal props
  zIndex = 100,

  opened = false,
  onClose,
  backgroundScroll = false,

  //* HTML article props
  className,
  children,
  ...restArticleProps
}: ModalProps) => {
  const openingTransition = useModalClosing({
    opened,
    closingDuration: 200,
  });

  useEffect(() => {
    const { classList } = document.body;
    const active = !!openingTransition;

    if (active) {
      classList.add(styles['body-size']);
      if (!backgroundScroll) classList.add(styles['fixed-body']);

      return () => {
        classList.remove(styles['body-size']);
        if (!backgroundScroll) classList.remove(styles['fixed-body']);
      };
    }
  }, [openingTransition, backgroundScroll]);

  return openingTransition ? (
    createPortal(
      <div
        className={cleanClassName(
          `${styles['modal-container']} ${
            openingTransition === MODAL_CLOSING_STATE.CLOSING && styles.closing
          }`,
        )}
        style={{
          zIndex,
        }}
      >
        <div className={styles['background-layer']} onClick={onClose} />
        <article
          {...restArticleProps}
          className={cleanClassName(`${styles.modal} ${className}`)}
        >
          <div className={styles['modal-contents']}>{children}</div>
        </article>
      </div>,
      document.body,
    )
  ) : (
    <></>
  );
};
