import type { HTMLTagProps } from '@/types';

import { CustomError, cn, optimizeImageUrl } from '@common/utils';

interface LazyImageProps extends HTMLTagProps<'img'> {
  width?: number;
  height?: number;
}

const IMAGE_PLACEHOLDER =
  'https://cdn.welfarehello.com/common/wello-profile.png';

export const LazyImage = ({
  loading = 'lazy',
  className,
  src,
  width,
  height,
  ...restProps
}: LazyImageProps) => {
  const imageSrc = typeof src === 'string' ? src : IMAGE_PLACEHOLDER;

  if (imageSrc.startsWith('/')) {
    throw new CustomError({
      return_message: `앱 자체 assets은 LazyImage 컴포넌트를 사용할 수 없습니다. - ${imageSrc}`,
    });
  }

  const highQualityImageSrcSet = `${optimizeImageUrl(imageSrc, {
    width,
    height,
    quality: 50,
    format: 'webp',
  })} 1x, ${optimizeImageUrl(imageSrc, {
    width: width && width * 1.3,
    height: height && height * 1.3,
    quality: 100,
    format: 'webp',
  })} 2x`;

  return (
    <picture>
      <source srcSet={highQualityImageSrcSet} type="image/webp" />
      <img
        {...restProps}
        alt={restProps.alt}
        className={cn(
          'max-w-[inherit] max-h-[inherit] object-cover transition-[filter]',
          className,
        )}
        height={height}
        loading={loading}
        src={optimizeImageUrl(imageSrc, {
          width,
          height,
          quality: 100,
          format: 'original',
        })}
        width={width}
      />
    </picture>
  );
};
