import type { HTMLTagProps } from '@/types';

import { useRef, useMemo } from 'react';

import { cleanClassName } from '@common/utils';

import styles from './AccordionContents.module.scss';
import { useAccordionContext } from './contexts/AccordionContext';

export type AccordionContentsProps = Omit<HTMLTagProps<'dd'>, 'style'> & {
  style?: Omit<React.CSSProperties, 'height'>;
};

export const AccordionContents = ({
  //* Accordion.Contents props
  style,

  //* HTML dd props
  children,
  className,
  ...restDdProps
}: AccordionContentsProps) => {
  const {
    openedState: [opened],
  } = useAccordionContext();
  const ref = useRef<HTMLElement>(null);

  const accordionContentsStyle = useMemo(() => {
    const { current } = ref;

    if (!current && opened) return style;

    if (!current) {
      return {
        ...style,
        height: 0,
      };
    }

    return {
      ...style,
      height: opened ? current.scrollHeight + 16 : 0,
    };
  }, [opened, style]);

  return (
    <dd
      {...restDdProps}
      className={styles['accordion-contents-wrap']}
      style={accordionContentsStyle}
    >
      <article className={styles['accordion-contents-article']}>
        <section
          ref={ref}
          className={cleanClassName(
            `${styles['accordion-contents']} ${style !== accordionContentsStyle && (opened ? styles.opened : styles.closed)} ${className}`,
          )}
        >
          {children}
        </section>
      </article>
    </dd>
  );
};
