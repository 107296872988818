import type { WelloPolicyMetaData } from '@/query-factory/filter';

import { Fragment, useEffect, useId, useRef, useState } from 'react';
import { scroller } from 'react-scroll';

import { Button, Chip } from '@common/components';
import { useQuery } from '@tanstack/react-query';
import { uniq } from 'es-toolkit';

import {
  BottomNavPortal,
  LazyImage,
  LoadingPageTemplate,
} from '@/components/client';
import { SELECTOR } from '@/constants';
import {
  EXCEPTED_CODE,
  EXCEPTED_HIDDEN_CODE,
  filterQueryOptions,
} from '@/query-factory/filter';

interface InterestPolicyFilter {
  onSubmit: (data: WelloPolicyMetaData) => void;
  isSubmitting?: boolean;
  submitButtonLabel?: string;
  children?: React.ReactNode;
  initSelectedMetaData: WelloPolicyMetaData;
  allowOnlyC14Code?: boolean;
}

export const InterestPolicyFilter = ({
  onSubmit,
  isSubmitting,
  submitButtonLabel,
  children,
  initSelectedMetaData,
  allowOnlyC14Code,
}: InterestPolicyFilter) => {
  const id = useId();

  const [selectedMetaDataMap, setSelectedMetaDataMap] = useState(
    new Map(
      initSelectedMetaData.map((metaData) => {
        const { code } = metaData;

        if (!code) throw new Error('metaData.code가 없습니다.');

        return [code, metaData];
      }),
    ),
  );

  const selectedCodeList = [...selectedMetaDataMap.keys()];

  //! ⚠️ 상태 값으로 관리할 필요가 없는 값
  const subTitleCodeList: string[] = [];
  const prevSubTitleCodeList = useRef(subTitleCodeList);

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const newSubTitleCodeList = subTitleCodeList.filter(
      (code) => !prevSubTitleCodeList.current.includes(code),
    );

    const scrollTo = newSubTitleCodeList[newSubTitleCodeList.length - 1];

    //! 애니메이션 후 스크롤
    if (scrollTo) {
      const scrollTimmer = setTimeout(() => {
        if (!isClicked) return;

        scroller.scrollTo(scrollTo, {
          smooth: true,
          offset: -100,
          containerId: SELECTOR.MAIN,
        });
      }, 300);

      return () => {
        clearTimeout(scrollTimmer);
        prevSubTitleCodeList.current = subTitleCodeList;
      };
    }

    return () => {
      prevSubTitleCodeList.current = subTitleCodeList;
    };
  });

  const { data } = useQuery(filterQueryOptions.policyFitMetaData());

  if (!data) return null;

  const interestedCategoryMetaDataList = allowOnlyC14Code
    ? data.interestedCategoryMetaDataList.filter(
        (metaData) => metaData.code && metaData.code.startsWith('C14'),
      )
    : data.interestedCategoryMetaDataList;

  const tempParentMetaDataMap = new Map<string, WelloPolicyMetaData[number]>();

  const applySelectedMap = () =>
    setSelectedMetaDataMap(
      (selectedMetaDataMap) => new Map(selectedMetaDataMap),
    );

  return !interestedCategoryMetaDataList ? (
    <LoadingPageTemplate />
  ) : (
    <form
      id={id}
      onSubmit={(e) => {
        e.preventDefault();

        onSubmit([...selectedMetaDataMap.values()]);
      }}
    >
      <dl className="flex flex-wrap gap-6 items-start justify-start m-0 px-20">
        {interestedCategoryMetaDataList?.map((metaData, index) => {
          const { code, value, parent_code } = metaData;

          let { level } = metaData;

          if (!code || !level) return null;

          if (code.startsWith('C14')) level = level - 1;

          switch (level) {
            case 1: {
              tempParentMetaDataMap.set(code, metaData);

              return (
                <dt
                  className="w-full typo-body4 flex items-center text-Gray-800 mt-26 mb-4 gap-4"
                  id={code}
                >
                  {metaData.image_url ? (
                    <LazyImage
                      height={18}
                      src={metaData.image_url}
                      width={18}
                    />
                  ) : null}
                  {value}
                </dt>
              );
            }

            case 2: {
              if (!parent_code) return null;

              const rootMetaData = tempParentMetaDataMap.get(parent_code);

              if (!rootMetaData) return null;

              const isDuplicatable = rootMetaData.duplicate_avail_yn;

              return (
                <dd key={index} className="w-fit m-0">
                  <Chip
                    checked={selectedMetaDataMap.has(code)}
                    className="py-5 px-10 typo-body4"
                    onCheckedChange={(checked) => {
                      //* 최신 선택된 코드 리스트를 가져오기 위한 함수
                      const getSelectedCodeList = () => [
                        ...selectedMetaDataMap.keys(),
                      ];

                      if (checked) {
                        if (isDuplicatable) {
                          selectedMetaDataMap.set(code, metaData);
                        } else {
                          const duplicatedCode = getSelectedCodeList().filter(
                            (code) => code.includes(parent_code),
                          );

                          duplicatedCode.forEach((code) => {
                            selectedMetaDataMap.delete(code);
                          });

                          selectedMetaDataMap.set(code, metaData);
                        }
                      } else {
                        getSelectedCodeList().forEach((selectedCode) => {
                          if (selectedCode.includes(code))
                            selectedMetaDataMap.delete(selectedCode);
                        });
                      }

                      //* 해당사항 없음에 대한 예외처리
                      if (code.includes(EXCEPTED_CODE.TARGET_CHARACTERISTICS)) {
                        const isTargetEmpty =
                          code ===
                          EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY;

                        if (checked) {
                          //* 해당 사항 없음 선택시 다른 선택된 대상 특성 삭제
                          if (isTargetEmpty) {
                            getSelectedCodeList().forEach((code) => {
                              if (
                                code.includes(
                                  EXCEPTED_CODE.TARGET_CHARACTERISTICS,
                                ) &&
                                code !==
                                  EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY
                              )
                                selectedMetaDataMap.delete(code);
                            });
                          } //* 다른 대상 특성 선택시 해당 사항 없음 삭제
                          else {
                            selectedMetaDataMap.delete(
                              EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY,
                            );
                          }
                        } //* 대상 특성에 아무것도 선택하지 않았을 때 해당 사항 없음 추가
                        else {
                          const hasSelectedTargetCharacteristics =
                            getSelectedCodeList().some((code) =>
                              code.includes(
                                EXCEPTED_CODE.TARGET_CHARACTERISTICS,
                              ),
                            );

                          if (!hasSelectedTargetCharacteristics) {
                            const targetCharacteristicsEmptyMetaData =
                              interestedCategoryMetaDataList.find(
                                ({ code }) =>
                                  code ===
                                  EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY,
                              );

                            if (!targetCharacteristicsEmptyMetaData)
                              throw new Error(
                                'meta data에 대상 특성 해당 없음이 없습니다.',
                              );

                            selectedMetaDataMap.set(
                              EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY,
                              targetCharacteristicsEmptyMetaData,
                            );
                          }
                        }
                      }

                      applySelectedMap();
                    }}
                    onClick={() => setIsClicked(true)}
                  >
                    {value}
                  </Chip>
                </dd>
              );
            }

            default: {
              if (!parent_code) return null;

              const hasSelectedParentMetaData =
                selectedMetaDataMap.has(parent_code);

              if (!hasSelectedParentMetaData) return null;

              //! ⚠️ 서버에서 받아온 선택했던 값들은 메타 데이터 값의 정보들이 완벽하지 않음 (ex. duplicate_avail_yn가 모두 false로 나오는 등)
              const parentMetaData = selectedMetaDataMap.get(parent_code);

              if (!parentMetaData) return null;

              const isFirstChild = !subTitleCodeList.includes(parent_code);

              const isDuplicatable = parentMetaData.duplicate_avail_yn;

              if (isFirstChild) {
                subTitleCodeList.push(parent_code);
              }

              return (
                <Fragment key={index}>
                  {isFirstChild ? (
                    <dt
                      className="w-full mt-26 mb-4 typo-body4 text-Gray-800 animate-fade-up duration-300"
                      id={parent_code}
                    >
                      {metaData.image_url ? (
                        <LazyImage
                          height={18}
                          src={metaData.image_url}
                          width={18}
                        />
                      ) : null}
                      {parentMetaData.value}
                    </dt>
                  ) : null}
                  <dd className="animate-fade-up duration-300 w-fit m-0">
                    <Chip
                      checked={selectedMetaDataMap.has(code)}
                      className="py-5 px-10 typo-body4"
                      onChange={(e) => {
                        const { checked } = e.target;

                        if (checked) {
                          if (isDuplicatable) {
                            selectedMetaDataMap.set(code, metaData);
                          } else {
                            const duplicatedCode = selectedCodeList.filter(
                              (code) =>
                                code !== parent_code &&
                                code.includes(parent_code),
                            );

                            duplicatedCode.forEach((code) => {
                              selectedMetaDataMap.delete(code);
                            });

                            selectedMetaDataMap.set(code, metaData);
                          }
                        } else {
                          selectedCodeList.forEach((selectedCode) => {
                            if (selectedCode.includes(code))
                              selectedMetaDataMap.delete(selectedCode);
                          });
                        }

                        applySelectedMap();
                      }}
                    >
                      {value}
                    </Chip>
                  </dd>
                </Fragment>
              );
            }
          }
        })}
      </dl>
      <BottomNavPortal hasShadow={false}>
        <footer className="gap-8 flex w-full px-20 py-16">
          {children}
          <Button
            className="flex-1"
            disabled={(() => {
              const selectedParentCodeOfCodeList: string[] = [];

              const hasInterestCode = selectedCodeList.some((code) =>
                code.startsWith('C14'),
              );

              if (!hasInterestCode) return true;

              selectedMetaDataMap.forEach((metaData) => {
                const { parent_code } = metaData;
                if (parent_code) selectedParentCodeOfCodeList.push(parent_code);
              });

              //* 선택안한 3차 이상 메타데이터 확인
              const tempHasChildrenSubMetaCodeList: string[] = [];

              interestedCategoryMetaDataList.forEach(
                ({ level, parent_code }) => {
                  if (2 < (level ?? 0) && parent_code)
                    tempHasChildrenSubMetaCodeList.push(parent_code);
                },
              );

              const hasChildrenSubMetaCodeList = uniq(
                tempHasChildrenSubMetaCodeList,
              );

              for (const [code, metaCode] of selectedMetaDataMap.entries()) {
                if ((metaCode.level ?? 0) < 2) continue;

                const hasChildren = hasChildrenSubMetaCodeList.includes(code);

                if (!hasChildren) continue;

                const isChildrenSelected =
                  selectedParentCodeOfCodeList.includes(code);

                if (!isChildrenSelected) return true;
              }

              return false;
            })()}
            form={id}
            loading={isSubmitting}
            type="submit"
          >
            {submitButtonLabel}
          </Button>
        </footer>
      </BottomNavPortal>
    </form>
  );
};
