'use client';

import { useState } from 'react';

import { Portal, Spinner } from '@common/components';
import { useBodyScrollLock } from '@common/hooks';
import { cn } from '@common/utils';

import { Skeleton } from '@/components/server';
import { SELECTOR } from '@/constants';

interface LoadingPageTemplateProps {
  blur?: boolean;
  fadeIn?: boolean;
  unmountedPlaceholder?: boolean;
}

const LIST_LENGTH = Array.from({ length: 100 });

export const LoadingPageTemplate = ({
  blur = false,
  fadeIn,
  unmountedPlaceholder,
}: LoadingPageTemplateProps) => {
  useBodyScrollLock(true, {
    selector: SELECTOR.MAIN,
  });

  const Loading = () => (
    <div
      className={cn(
        'absolute top-0 left-0 flex justify-center w-full h-full z-40 overflow-hidden',
        {
          'animate-fade duration-300 animate-delay-200': fadeIn,
          'z-50': blur,
        },
      )}
    >
      {blur ? null : (
        <ul className="w-full bg-Gray-50 h-full px-20 py-18 flex-col gap-8 flex z-10 justify-center">
          {LIST_LENGTH.map((_, index) => (
            <li
              key={index}
              className="w-full bg-Gray-white p-15 flex gap-12 rounded-medium"
            >
              <Skeleton className="w-56 h-56 rounded-mlarge" />
              <div className="flex flex-col gap-12 w-full flex-1">
                <Skeleton className="w-4/5 h-23 rounded-xsmall" />
                <Skeleton className="w-1/2 h-16 rounded-xsmall" />
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="flex flex-col gap-16 items-center justify-center w-full h-full text-Gray-900 bg-opacity-80 bg-[#1a1a1a] absolute z-20">
        <Spinner logo color="white" size={80} />
      </div>
    </div>
  );

  const [isMounted, setIsMounted] = useState(false);

  return (
    <>
      {unmountedPlaceholder && !isMounted ? <Loading /> : null}
      <Portal
        renderTo={() => document.getElementById(SELECTOR.MAIN_WRAPPER)}
        onLoaded={() => setIsMounted(true)}
      >
        <Loading />
      </Portal>
    </>
  );
};
