import type { FlutterInAppWebViewWindow } from '@/modules/wello-app';

import { useEffect } from 'react';
import { Settings } from 'react-feather';

import { Button, Portal } from '@common/components';
import {
  SvgChevronleft,
  SvgDelete,
  SvgExpandWindow,
  SvgHome,
  SvgKebab,
  SvgMagnifyingGlass,
  SvgNotification,
} from '@common/icon';
import { cn } from '@common/utils';
import { usePathname } from 'next/navigation';

import Logo from '@/assets/svgs/logo.svg';
import { CustomLink } from '@/components/atom/CustomLink';
import { MODULE_ROUTES, ROUTES, SELECTOR } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { WEBVIEW_TYPE, useDeviceInfoStore } from '@/stores/DeviceStore';
import { useGlobalStore } from '@/stores/GlobalStore';
import { findRoute } from '@/utils';

interface PageNavigationProps {
  className?: string;
  title?: string | React.ReactNode;
  titleLeft?: string | React.ReactNode;
  children?: React.ReactNode;
  logo?: boolean;
  searchButton?: boolean;
  bellButton?: boolean;
  shareButton?: boolean;
  notification?: boolean;
  settingsButton?: boolean;
  backButton?: boolean;
  backButtonType?: 'exit' | 'back';
  kebabButton?: boolean;
  onClickKebab?: VoidFunction;
  onClickBack?: VoidFunction | string;
  style?: React.CSSProperties;
  backButtonColor?: string;
}

export const PageNavigation = ({
  logo,
  title,
  titleLeft,
  children,
  backButton = true,
  backButtonType = 'back',
  searchButton,
  bellButton,
  notification,
  shareButton,
  settingsButton,
  kebabButton,
  onClickBack,
  onClickKebab,
  className,
  style,
  backButtonColor,
}: PageNavigationProps) => {
  const router = useCustomRouter();

  const setShareLink = useGlobalStore((state) => state.setShareLink);
  const [isKbWebview, setAppModal] = useDeviceInfoStore((state) => [
    state.webviewType === WEBVIEW_TYPE.KB_PAY,
    state.setAppModal,
  ]);

  useEffect(() => {
    const _window = window as FlutterInAppWebViewWindow;

    if (_window.router) {
      _window.router.back = backButton
        ? () => router.back()
        : () => {
            setAppModal({ isOpened: true, type: 'exit' });
          };

      return () => {
        _window.router.back = () => {};
      };
    }
  }, [backButton, router, setAppModal]);

  const pathname = usePathname();
  const currentRoute = findRoute(pathname);
  const hasBottomNav = currentRoute?.hasBottomNav;

  if (isKbWebview && pathname !== MODULE_ROUTES.KB_HOME.pathname) return null;

  return (
    <Portal
      placeholder={
        <h3 className="text-Gray-900 font-600 typo-body3 flex items-center justify-center text-center break-all min-h-56 box-border bg-Gray-white">
          {title}
        </h3>
      }
      renderTo={() => document.getElementById(SELECTOR.PAGE_NAV_WRAPPER)}
    >
      <nav
        className={cn(
          'grid grid-cols-2 items-center relative',
          'min-h-56 box-border bg-Gray-white text-center',
          className,
        )}
        style={style}
      >
        {titleLeft ? (
          <h3 className="absolute flex items-center justify-center left-20 typo-h6 font-700">
            {titleLeft}
          </h3>
        ) : null}
        {title ? (
          <h3
            className={cn(
              'absolute flex items-center justify-center w-full h-full',
              'text-Gray-900 font-600 typo-body3 text-center break-all',
            )}
          >
            {title}
          </h3>
        ) : null}
        <div className="flex pl-10 z-10 gap-8">
          {logo ? (
            <CustomLink
              aria-label="웰로"
              className="ml-10"
              href={ROUTES.HOME.pathname}
            >
              <Logo className="w-86 h-29" />
            </CustomLink>
          ) : null}
          {backButton ? (
            <Button
              aria-label="뒤로 가기"
              className="relative size-26 p-0"
              dimensions="H26"
              theme="primary-text"
              onClick={
                onClickBack
                  ? typeof onClickBack === 'string'
                    ? () => router.replace(onClickBack)
                    : onClickBack
                  : () => router.back()
              }
            >
              {backButtonType === 'back' ? (
                <SvgChevronleft className={cn('size-20', backButtonColor)} />
              ) : (
                <SvgDelete className="size-20" />
              )}
            </Button>
          ) : null}
        </div>
        <div className="flex justify-end pr-10 z-10 gap-20">
          {children}
          {!hasBottomNav ? (
            <Button
              aria-label="홈으로 가기"
              className="relative size-26 p-0"
              dimensions="H26"
              theme="primary-text"
              onClick={() => router.push(ROUTES.HOME.pathname)}
            >
              <SvgHome className="size-18" />
            </Button>
          ) : null}
          {shareButton ? (
            <Button
              aria-label="공유하기"
              className="relative size-26 p-0"
              dimensions="H26"
              theme="primary-text"
              onClick={() => {
                setShareLink(location.href);
              }}
            >
              <SvgExpandWindow className="size-18" />
            </Button>
          ) : null}
          {settingsButton ? (
            <Button
              aria-label="설정"
              className="relative size-26 p-0"
              dimensions="H26"
              theme="primary-text"
              onClick={() => router.push(ROUTES.SETTINGS.pathname)}
            >
              <Settings className="size-20" />
            </Button>
          ) : null}
          {searchButton ? (
            <Button
              aria-label="검색으로 이동"
              className="relative size-26 p-0"
              dimensions="H26"
              theme="primary-text"
              onClick={() => {
                router.push(ROUTES.SEARCH.pathname);
              }}
            >
              <SvgMagnifyingGlass className="size-20" />
            </Button>
          ) : null}
          {bellButton ? (
            <Button
              aria-label="알림으로 이동"
              className="relative size-26 p-0"
              dimensions="H26"
              theme="primary-text"
              onClick={() => {
                router.push(ROUTES.PUSH.pathname);
              }}
            >
              <SvgNotification className="size-20" />
              {notification ? (
                <span className="absolute top-12 right-14 w-4 h-4 bg-R-500 border-Gray-100 border-2 rounded-pill box-content" />
              ) : null}
            </Button>
          ) : null}
          {kebabButton ? (
            <Button
              aria-label="더보기"
              className="relative size-26 p-0"
              dimensions="H26"
              theme="primary-text"
              onClick={onClickKebab}
            >
              <SvgKebab className="size-18" />
            </Button>
          ) : null}
        </div>
      </nav>
    </Portal>
  );
};
