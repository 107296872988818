'use client';

import type { LinkProps } from 'next/link';

import { forwardRef } from 'react';

import Link from 'next/link';

import { STORAGE_KEY } from '@/constants';

interface CustomLinkProps
  extends Omit<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      keyof LinkProps | 'ref'
    >,
    LinkProps {}

export const CustomLink = forwardRef<HTMLAnchorElement, CustomLinkProps>(
  ({ replace, children, onClick, ...props }: CustomLinkProps, ref) => {
    return (
      <Link
        {...props}
        ref={ref}
        replace={replace}
        onClick={(e) => {
          if (replace) {
            sessionStorage.setItem(STORAGE_KEY.REPLACE_MARK, 'true');
          }

          onClick?.(e);
        }}
      >
        {children}
      </Link>
    );
  },
);

CustomLink.displayName = 'CustomLink';
