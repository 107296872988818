import { CustomLink } from '@/components/atom/CustomLink';
import { LazyImage } from '@/components/client';
import {
  Skeleton,
  NewsCardFooter,
  type NewsCardFooterProps,
} from '@/components/server';

import styles from './PolicyInfoMiniCard.module.scss';

interface PolicyInfoMiniCardProps extends NewsCardFooterProps {
  title?: string;
  dateText?: string;
  thumbnail?: string;
  href?: string;
}

export const PolicyInfoMiniCard = ({
  title,
  dateText,
  href = '#',
  isLoading,
  thumbnail,
  ...restProps
}: PolicyInfoMiniCardProps) => {
  return (
    <article className={styles['policy-card']}>
      <CustomLink className={styles.link} href={href}>
        {isLoading ? (
          <Skeleton className={styles.thumbnail} />
        ) : (
          <LazyImage
            alt={`policy-card-${title}`}
            className={styles.thumbnail}
            src={thumbnail}
          />
        )}
        <div className={styles['card-info']}>
          {isLoading ? (
            <div className={styles['title-skeleton-container']}>
              <Skeleton className={styles['title-skeleton']} />
              <Skeleton className={styles['title-skeleton']} />
            </div>
          ) : (
            <h3 className={styles.title}>{title}</h3>
          )}
          {isLoading ? (
            <Skeleton className={styles['date-text-skeleton']} />
          ) : (
            <p className={styles['date-text']}>{dateText}</p>
          )}
        </div>
      </CustomLink>
      <NewsCardFooter {...restProps} isLoading={isLoading} />
    </article>
  );
};
