import type { ButtonProps } from '@/components/server';
import type { HTMLTagProps } from '@/types';

import { cleanClassName } from '@common/utils';

import styles from './Tab.module.scss';
import { TabItem } from './TabItem';
import { TabContextProvider } from './contexts/TabContext';
import { useTabOverflowX } from './hooks/useTabOverflowX';

export type { TabItemProps } from './TabItem';

export type TabProps = Omit<HTMLTagProps<'ul'>, 'size' | 'ref'> &
  Pick<ButtonProps, 'size' | 'shape'>;

export const Tab = Object.assign(
  ({
    //* Button props
    size,
    shape = 'rectangle',

    //* HTML ul props
    className,
    children,
    ...restUlProps
  }: TabProps) => {
    const { isOverflowX, ref } = useTabOverflowX();

    return (
      <ul
        {...restUlProps}
        ref={ref}
        className={cleanClassName(
          `${styles.tab} ${
            shape === 'rectangle' && styles['shape-rectangle']
          } ${isOverflowX} ${className}`,
        )}
      >
        <TabContextProvider shape={shape} size={size}>
          {children}
        </TabContextProvider>
      </ul>
    );
  },
  {
    Item: TabItem,
  },
);
