import { Button } from '@common/components';
import {
  SvgBookmark,
  SvgBookmarkFill,
  SvgChatbubbleTyping,
  SvgLike,
  SvgLikeFill,
  SvgShareLink,
} from '@common/icon';

import { useGlobalStore } from '@/stores/GlobalStore';

export interface CardUserActionFooterProps {
  isThumbUp?: boolean;
  thumbUpCount?: number;
  commentCount?: number;
  isBookmarked?: boolean;
  onClickThumbUp?: () => void;
  onClickBookmark?: () => void;
  onClickComment?: () => void;
  shareLink?: string;
}

const STYLES = {
  BUTTON: 'flex flex-col gap-8 w-fit h-80',
  BUTTON_TITLE: 'font-400 typo-caption1 whitespace-nowrap',
  ICON: 'size-24',
};

export const CardUserActionFooter = ({
  thumbUpCount = 0,
  commentCount = 0,
  isBookmarked,
  isThumbUp,
  onClickThumbUp,
  onClickComment,
  onClickBookmark,
  shareLink,
}: CardUserActionFooterProps) => {
  return (
    <footer className="flex justify-between">
      <Button
        className={STYLES.BUTTON}
        dimensions="H56"
        theme="sub-text"
        onClick={onClickThumbUp}
      >
        {isThumbUp ? (
          <SvgLikeFill className={STYLES.ICON} />
        ) : (
          <SvgLike className={STYLES.ICON} />
        )}
        <span className={STYLES.BUTTON_TITLE}>
          {thumbUpCount.toLocaleString()}
        </span>
      </Button>
      <Button
        className={STYLES.BUTTON}
        theme="sub-text"
        onClick={onClickComment}
      >
        <SvgChatbubbleTyping className={STYLES.ICON} />
        <span className={STYLES.BUTTON_TITLE}>
          {commentCount.toLocaleString()}
        </span>
      </Button>
      <Button
        aria-label={isBookmarked ? '북마크 제거' : '북마크 추가'}
        className={STYLES.BUTTON}
        theme="sub-text"
        onClick={onClickBookmark}
      >
        {isBookmarked ? (
          <SvgBookmarkFill className={STYLES.ICON} />
        ) : (
          <SvgBookmark className={STYLES.ICON} />
        )}
        <span className={STYLES.BUTTON_TITLE}>스크랩</span>
      </Button>
      <Button
        className={STYLES.BUTTON}
        theme="sub-text"
        onClick={() => {
          useGlobalStore
            .getState()
            .setShareLink(shareLink ?? window.location.href);
        }}
      >
        <SvgShareLink className={STYLES.ICON} />
        <span className={STYLES.BUTTON_TITLE}>공유</span>
      </Button>
    </footer>
  );
};
