import { CustomError, createUuid } from '@common/utils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ky from 'ky';

import { UNAUTH_API } from '@/api';
import { termsQueryOptions } from '@/query-factory/terms';
import { useAuthStore } from '@/stores/AuthStore';
import { useGlobalStore } from '@/stores/GlobalStore';

export const DEFAULT_LOCATION = {
  isDefaultLocation: true,
  isLocationServiceConsent: false,
  hasLocationPermission: false,
  longitude: 126.95629,
  latitude: 37.47793,
  region: '서울특별시',
  codeRegion: 'C01-01',
  subRegion: '관악구',
  codeSubRegion: 'C01-01-21',
};

const LOCATION_DRAWER_SESSION_KEY = 'denied-location-drawer';
export const LOCATION_QUERY_KEY = createUuid();

type GeolocationResponse = Awaited<
  | ReturnType<typeof UNAUTH_API.getGeocoding>
  | ReturnType<typeof UNAUTH_API.getGeolocation>
>;

export const useLocation = (enabledInitialFetch = false) => {
  const isLogin = useAuthStore((state) => state.isLogin);

  const locationServiceConsent = useQuery(
    termsQueryOptions.locationServiceConsent(isLogin),
  );

  const locationDrawerCallback = useGlobalStore(
    ({ setHandleLocationServiceConsentDrawer }) =>
      setHandleLocationServiceConsentDrawer,
  );

  return useQuery({
    queryKey: [LOCATION_QUERY_KEY, locationServiceConsent.data],
    placeholderData: keepPreviousData,
    staleTime: 0,
    gcTime: 0,
    queryFn: async () => {
      const isLocationServiceConsent = locationServiceConsent.data;

      if (isLocationServiceConsent === undefined)
        throw new CustomError({
          return_message: '위치 기반 서비스 동의 정보가 없습니다.',
        });

      if (!isLocationServiceConsent) {
        let isDenied =
          sessionStorage.getItem(LOCATION_DRAWER_SESSION_KEY) === 'true';

        if (isDenied) return DEFAULT_LOCATION;

        isDenied = await new Promise((resolve) => {
          locationDrawerCallback(async (isAgree) => {
            resolve(!isAgree);
          });
        });

        if (isDenied) {
          sessionStorage.setItem(LOCATION_DRAWER_SESSION_KEY, 'true');

          return DEFAULT_LOCATION;
        }
      }

      let hasLocationPermission = true;

      const res = await new Promise<GeolocationResponse['context']>(
        (resolve) => {
          navigator.geolocation.getCurrentPosition(
            async ({ coords }) => {
              const { context } = await UNAUTH_API.getGeocoding({
                coords: `${coords.longitude},${coords.latitude}`,
              });

              resolve({
                ...context,
                latitude: String(coords.latitude),
                longitude: String(coords.longitude),
              });
            },

            async (error) => {
              if (error.code === error.PERMISSION_DENIED) {
                hasLocationPermission = false;
              }

              const ip = await ky.get<string>('https://api.ip.pe.kr').text();

              const { context } = await UNAUTH_API.getGeolocation({
                ip,
              });

              resolve(context);
            },
            {
              enableHighAccuracy: true,
              timeout: 5_000,
              maximumAge: 0,
            },
          );
        },
      );

      const { region, code_region, sub_region, code_sub_region } = res ?? {};

      if (!res || !region || !code_region || !sub_region || !code_sub_region)
        throw new CustomError({
          return_message: '위치 정보를 가져오는데 실패했습니다.',
        });

      return {
        isDefaultLocation: false,
        isLocationServiceConsent,
        hasLocationPermission,
        longitude: res.longitude ? Number(res.longitude) : null,
        latitude: res.latitude ? Number(res.latitude) : null,
        region,
        codeRegion: code_region,
        subRegion: sub_region,
        codeSubRegion: code_sub_region,
      };
    },
    enabled:
      isLogin !== undefined &&
      locationServiceConsent.data !== undefined &&
      enabledInitialFetch,
  });
};

export type LocationData = Exclude<
  Awaited<ReturnType<typeof useLocation>>['data'],
  undefined
>;
