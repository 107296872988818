import type { CardUserActionFooterProps } from '@/components/server';

import { cleanClassName } from '@common/utils';

import { LazyImage } from '@/components/client';
import { Badge, LinkableBox, CardUserActionFooter } from '@/components/server';

import styles from './HometownNewsDetailCard.module.scss';

export interface HometownNewsDetailCardProps extends CardUserActionFooterProps {
  thumbnail?: string;
  title?: string;
  badge?: string;
  createdAt?: string;
  hasAnimation?: boolean;
  href?: string;
}

export const HometownNewsDetailCard = ({
  thumbnail,
  title,
  badge,
  createdAt,
  hasAnimation,
  href,
  ...hometownCardFooterProps
}: HometownNewsDetailCardProps) => {
  return (
    <LinkableBox
      className={cleanClassName(
        `${styles.card} ${hasAnimation && styles.animated}`,
      )}
      href={href}
    >
      <LazyImage alt={title} className={styles.thumbnail} src={thumbnail} />
      <section className={styles['info-container']}>
        <Badge>{badge}</Badge>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles['date-text']}>
          <span>작성일 | </span>
          {createdAt}
        </p>
      </section>
      <CardUserActionFooter {...hometownCardFooterProps} />
    </LinkableBox>
  );
};
