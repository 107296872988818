import type { SEARCH_PARAMS_TYPE } from '@/constants';
import type { LocationData } from '@/hooks/useLocation';
import type { WelloPolicyMetaData } from '@/query-factory/filter';

import { useId, useState } from 'react';

import {
  Button,
  Input,
  BottomDrawer,
  Spinner,
  Skeleton,
} from '@common/components';
import { useToast } from '@common/hooks';
import { SvgCheck, SvgChevrondown, SvgTarget2 } from '@common/icon';
import { CustomError, cn } from '@common/utils';
import { useQuery } from '@tanstack/react-query';

import { SELECTOR } from '@/constants';
import { useLocation } from '@/hooks/useLocation';
import { filterQueryOptions } from '@/query-factory/filter';

export interface SelectedRegion {
  mainRegion: WelloPolicyMetaData[number];
  subRegion: WelloPolicyMetaData[number];
}

export interface SelectedLocation {
  latitude: number;
  longitude: number;
}

interface RegionSelectProps {
  value?: SEARCH_PARAMS_TYPE.SELECTED_REGION;
  onChange?: (value: SelectedRegion) => void;
  onClickGps?: (value: LocationData) => void;
  id?: string;
  className?: string;
  labelClassName?: string;
  label?: string;
  isLoading?: boolean;
  enableMainRegionCodeList?: string[];
  enableLocation?: boolean;
}

export const RegionSelect = ({
  value,
  onChange,
  onClickGps,
  id,
  className,
  labelClassName,
  label = '지역',
  isLoading = false,
  enableMainRegionCodeList,
  enableLocation = true,
}: RegionSelectProps) => {
  const policyFitMetaDataQuery = useQuery(
    filterQueryOptions.policyFitMetaData(),
  );

  const { regionMetaData } = policyFitMetaDataQuery.data ?? {};

  const { refetch: getLocation, isLoading: isLocationLoading } = useLocation();

  const { toast } = useToast();

  const [selectedValue, setSelectedValue] =
    useState<SEARCH_PARAMS_TYPE.SELECTED_REGION>();

  const mainRegionList = regionMetaData?.mainRegion.filter((mainRegion) => {
    if (!enableMainRegionCodeList) return true;

    return enableMainRegionCodeList.includes(mainRegion.code ?? '');
  });

  const mainRegion = mainRegionList?.find(
    ({ code }) => code === value?.mainRegionCode,
  );

  const subRegion = regionMetaData?.subRegion.find(
    ({ code }) => code === value?.subRegionCode,
  );

  const selectedRegionText =
    mainRegion && subRegion ? `${mainRegion.value} > ${subRegion.value}` : '';

  const inputId = useId();

  const isPending =
    isLoading || isLocationLoading || policyFitMetaDataQuery.isLoading;

  return (
    <div className={className}>
      <div className="flex gap-4 items-center typo-body4 text-Gray-800 justify-between w-full mb-10">
        <label className={labelClassName} htmlFor={inputId} id={id}>
          {label}
        </label>
        {enableLocation ? (
          isPending ? (
            <Skeleton className="w-55 h-21" />
          ) : (
            <button
              className="typo-body4 text-Gray-900 font-400 flex gap-5 items-center"
              type="button"
              onClick={async () => {
                const { data: location } = await getLocation();

                const { codeRegion, codeSubRegion } = location ?? {};

                const mainRegion = mainRegionList?.find(
                  ({ code }) => code === codeRegion,
                );

                const subRegion = regionMetaData?.subRegion.find(
                  ({ code }) => code === codeSubRegion,
                );

                if (!mainRegion || !subRegion)
                  throw new CustomError({
                    return_message: '위치 정보를 가져오는데 실패했습니다.',
                  });

                onChange?.({
                  mainRegion,
                  subRegion,
                });

                toast({
                  message: '현재 위치가 설정되었어요.',
                });

                if (!onClickGps) return;
                if (onClickGps) {
                  if (!location)
                    throw new CustomError({
                      return_message: '위치 정보를 가져오는데 실패했습니다.',
                    });

                  onClickGps(location);
                }
              }}
            >
              {isLocationLoading ? (
                <Spinner size={14} />
              ) : (
                <SvgTarget2 className="size-14" name="위치 정보 업데이트" />
              )}
              현위치
            </button>
          )
        ) : null}
      </div>
      <Input
        readOnly
        className="w-full"
        id={inputId}
        placeholder="지역을 선택해 주세요."
        rightContent={
          isPending ? (
            <Spinner size={20} />
          ) : (
            <SvgChevrondown className="size-20 text-Gray-700" name="열기" />
          )
        }
        type="button"
        value={selectedRegionText}
        onClick={() => {
          if (isPending) return;

          setSelectedValue(
            value?.mainRegionCode
              ? value
              : {
                  mainRegionCode: 'C01-01',
                },
          );
        }}
      />
      <BottomDrawer
        opened={!!selectedValue}
        renderTo={() => document.getElementById(SELECTOR.MAIN_WRAPPER)}
        onClose={() => {
          setSelectedValue(undefined);
        }}
      >
        <BottomDrawer.HandleWrapper>
          <BottomDrawer.Handle />
        </BottomDrawer.HandleWrapper>
        <BottomDrawer.Contents className="overflow-hidden">
          <h6 className="typo-body1 font-700 px-10 mb-20">
            관심 지역을 선택해 주세요
          </h6>
          <section className="max-h-300 flex">
            <ul className="overflow-auto flex-1 border-r-1 border-Gray-300 hide-scrollbar px-5 webkit-scroll-touch">
              {mainRegionList?.map(({ value, code }) => {
                if (!code || !value) return null;

                const isSelected = code === selectedValue?.mainRegionCode;

                return (
                  <li
                    key={code}
                    className={cn(
                      'px-12 py-8 text-nowrap typo-body3 font-400 text-Gray-800 flex justify-between items-center cursor-pointer',
                      {
                        'font-600 text-B-500': isSelected,
                      },
                    )}
                    onClick={() => {
                      if (code === selectedValue?.mainRegionCode) return;

                      setSelectedValue({
                        mainRegionCode: code,
                      });
                    }}
                  >
                    {value}
                    {isSelected ? (
                      <SvgCheck
                        className="size-16 text-B-500 stroke-B-500"
                        name="Check"
                        strokeWidth={1}
                      />
                    ) : null}
                  </li>
                );
              })}
            </ul>
            <ul className="overflow-auto flex-1 hide-scrollbar px-5 webkit-scroll-touch">
              {regionMetaData?.subRegion
                .filter(
                  ({ parent_code }) =>
                    parent_code === selectedValue?.mainRegionCode,
                )
                .sort((a, b) => {
                  if (
                    typeof a.value !== 'string' ||
                    typeof b.value !== 'string'
                  ) {
                    return 0;
                  }

                  return a?.value.localeCompare(b?.value);
                })
                .map(({ code, value }) => {
                  if (!code || !value) return null;

                  const isSelected = code === selectedValue?.subRegionCode;

                  return (
                    <li
                      key={code}
                      className={cn(
                        'px-12 py-8 text-nowrap typo-body3 font-400 text-Gray-800 flex justify-between items-center cursor-pointer animate-in slide-in-from-top duration-300',
                        {
                          'font-600 text-B-500': isSelected,
                        },
                      )}
                      onClick={() => {
                        if (code === selectedValue?.subRegionCode) return;

                        setSelectedValue((prev) => ({
                          ...prev,
                          subRegionCode: code,
                        }));
                      }}
                    >
                      {value}
                      {isSelected ? (
                        <SvgCheck
                          className="size-16 text-B-500 stroke-B-500"
                          name="Check"
                          strokeWidth={1}
                        />
                      ) : null}
                    </li>
                  );
                })}
            </ul>
          </section>
          <footer className="py-16 px-20 w-full mt-16">
            <Button
              className="w-full"
              disabled={
                !selectedValue?.mainRegionCode || !selectedValue?.subRegionCode
              }
              onClick={() => {
                const mainRegion = mainRegionList?.find(
                  ({ code }) => code === selectedValue?.mainRegionCode,
                );

                const subRegion = regionMetaData?.subRegion.find(
                  ({ code }) => code === selectedValue?.subRegionCode,
                );

                if (!mainRegion || !subRegion)
                  throw new CustomError({
                    return_message: '지역 정보를 가져오지 못했어요.',
                  });

                onChange?.({
                  mainRegion,
                  subRegion,
                });

                setSelectedValue(undefined);
              }}
            >
              선택 완료
            </Button>
          </footer>
        </BottomDrawer.Contents>
      </BottomDrawer>
    </div>
  );
};
