import type { HTMLTagProps, Size } from '@/types';

import { forwardRef } from 'react';

import { cleanClassName } from '@common/utils';

import { InputWrap, type InputWrapProps } from '@/components/client';

import styles from './Textarea.module.scss';

export interface TextareaProps
  extends Pick<
      InputWrapProps,
      'className' | 'style' | 'label' | 'reversed' | 'shape' | 'message'
    >,
    Omit<
      HTMLTagProps<'textarea'>,
      'resize' | 'className' | 'style' | 'children'
    > {
  size?: Size;
  validationMessage?: string;
  value?: string;
  children?: React.ReactNode;
  ref?: React.ForwardedRef<HTMLTextAreaElement>;
  textMaxHeight?: string;
  decorationAlign?: 'top' | 'bottom' | 'center';
}

/**
 *
 * @deprecated 공통 컴포넌트를 사용해주세요.
 */
export const Textarea = forwardRef<
  HTMLTextAreaElement,
  Omit<TextareaProps, 'ref'>
>(
  (
    {
      //* Textarea props
      size = 'large',
      value,
      onChange,
      children,
      shape,
      rows = 1,
      textMaxHeight,
      decorationAlign = 'center',

      //* InputWrap props
      validationMessage,
      className,
      style,
      label,
      reversed,
      message,

      //* HTML textarea props
      onInvalid,
      ...restTextareaProps
    },
    ref,
  ) => {
    const handleTextareaHeight = (
      target: EventTarget & HTMLTextAreaElement,
    ) => {
      const { style } = target;
      style.height = 'auto';
      style.height = `${target.scrollHeight}px`;
    };

    const { required } = restTextareaProps;

    return (
      <InputWrap
        className={className}
        label={label}
        message={message}
        required={required}
        shape={shape}
        size="fit-content"
        style={style}
        validationMessage={validationMessage}
      >
        <div
          className={cleanClassName(
            `${styles['textarea-wrap']} ${styles[size]} ${
              reversed && styles.reversed
            } ${styles[decorationAlign]}`,
          )}
        >
          <textarea
            {...restTextareaProps}
            ref={ref}
            className={styles.textarea}
            required={required}
            rows={rows}
            style={
              textMaxHeight
                ? {
                    maxHeight: textMaxHeight,
                    height: value ? undefined : 'auto',
                  }
                : undefined
            }
            value={value}
            onChange={(e) => {
              onChange?.(e);
              handleTextareaHeight(e.target);
            }}
            onInvalid={(e) => {
              e.preventDefault();
              onInvalid?.(e);
            }}
          />
          {children}
        </div>
      </InputWrap>
    );
  },
);

Textarea.displayName = 'Textarea';
