import { BottomDrawer } from '@common/components';

import { SELECTOR } from '@/constants';

export interface DrawerProps {
  opened?: boolean;
  onClose?: () => void;
  className?: string;
  children: React.ReactNode;
  backdropClassName?: string;
  containerClassName?: string;
}

export const Drawer = ({
  opened,
  onClose,
  children,
  className,
  backdropClassName,
  containerClassName,
}: DrawerProps) => {
  return (
    <BottomDrawer
      className={{
        backdrop: backdropClassName,
        drawer: containerClassName,
      }}
      opened={opened}
      renderTo={() => document.getElementById(SELECTOR.MAIN_WRAPPER)}
      onClose={onClose}
    >
      <BottomDrawer.HandleWrapper>
        <BottomDrawer.Handle />
      </BottomDrawer.HandleWrapper>
      <BottomDrawer.Contents className={className}>
        {children}
      </BottomDrawer.Contents>
    </BottomDrawer>
  );
};
