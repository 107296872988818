import { Button } from '@common/components';

interface EmptySectionProps {
  mainMessage: string;
  subMessage?: string;
  actionButton?: {
    text: string;
    onClick: VoidFunction;
  };
}

export const EmptySection = ({
  mainMessage,
  subMessage,
  actionButton,
}: EmptySectionProps) => {
  return (
    <section className="flex-center flex-col py-150 px-20 text-center break-keep">
      <strong className="typo-body3 font-500 text-Gray-900">
        {mainMessage}
      </strong>
      {subMessage ? (
        <p className="typo-body4 font-400 text-Gray-600 whitespace-pre-wrap">
          {subMessage}
        </p>
      ) : null}
      {actionButton ? (
        <Button
          className="w-full mt-24"
          roundness="capsule"
          onClick={actionButton.onClick}
        >
          {actionButton.text}
        </Button>
      ) : null}
    </section>
  );
};
