import type { HTMLTagProps } from '@/types';

import { useEffect, useRef, useState } from 'react';
import { Check } from 'react-feather';

import { cleanClassName } from '@common/utils';

import { useGetter } from '@/hooks/useGetter';
import { useModalClosing, MODAL_CLOSING_STATE } from '@/hooks/useModalClosing';

import styles from './Option.module.scss';

export type ValidOptionValue = string | number;

export interface SelectChangeEvent<
  TOptionValue extends ValidOptionValue,
  TMultiple extends boolean,
  TCancelable extends boolean,
> {
  value: TMultiple extends true
    ? TOptionValue[]
    : TCancelable extends true
      ? TOptionValue | undefined
      : TOptionValue;
}

export type OptionProps<
  TOptionValue extends ValidOptionValue,
  TMultiple extends boolean = false,
  TCancelable extends boolean = false,
> = Omit<HTMLTagProps<'section'>, 'value' | 'onChange' | 'onKeyDown'> & {
  opened?: boolean;
  options?: {
    label: string;
    value: TOptionValue;
  }[];
  multiple?: TMultiple;
  cancelable?: TCancelable;
  value?: TMultiple extends true ? TOptionValue[] : TOptionValue | undefined;
  onChange?: (
    event: SelectChangeEvent<TOptionValue, TMultiple, TCancelable>,
  ) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  float?: 'top' | 'bottom';
};

const INITIAL_INDEX = -1;

export const Option = <
  TOptionValue extends ValidOptionValue = ValidOptionValue,
  TMultiple extends boolean = false,
  TCancelable extends boolean = false,
>({
  //* Select props
  opened = false,
  options,
  multiple = false as TMultiple,
  cancelable = false as TCancelable,
  value: selectedValue,
  onChange,
  onKeyDown,
  float = 'bottom',

  //* HTML section props
  className,
  ...restSectionProps
}: OptionProps<TOptionValue, TMultiple, TCancelable>) => {
  const closingTransition = useModalClosing({
    opened,
    closingDuration: 100,
  });

  const optionItemRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const indexForSelectState = useState(INITIAL_INDEX);
  const [indexForSelect, setIndexForSelect] = indexForSelectState;

  const getIndexForSelect = useGetter(indexForSelect);

  useEffect(() => {
    if (closingTransition === MODAL_CLOSING_STATE.OPENED && options) {
      const prevFocusedElement = document.activeElement;

      const focusOptionItem = (indexForSelect: number) => {
        optionItemRefs.current[indexForSelect]?.focus();
        setIndexForSelect(indexForSelect);
      };

      const keyboardEvent = (event: KeyboardEvent) => {
        onKeyDown?.(event);

        let indexForSelect = getIndexForSelect();

        switch (event.key) {
          case 'ArrowUp':
            event.preventDefault();

            if (indexForSelect > 0) {
              indexForSelect -= 1;
              focusOptionItem(indexForSelect);
            }

            break;

          case 'ArrowDown':
            event.preventDefault();

            if (indexForSelect < options.length - 1) {
              indexForSelect += 1;
              focusOptionItem(indexForSelect);
            }

            break;

          case 'Enter':
            event.preventDefault();

            if (indexForSelect !== INITIAL_INDEX)
              optionItemRefs.current[indexForSelect]?.click();

            break;

          default: {
            if (prevFocusedElement instanceof HTMLElement)
              prevFocusedElement?.focus();
          }
        }
      };

      document.addEventListener('keydown', keyboardEvent);

      return () => document.removeEventListener('keydown', keyboardEvent);
    }
  }, [
    getIndexForSelect,
    onKeyDown,
    closingTransition,
    options,
    optionItemRefs,
    setIndexForSelect,
  ]);

  return closingTransition && options?.length ? (
    <section
      {...restSectionProps}
      className={cleanClassName(
        `${styles.option} ${styles[float]} ${
          closingTransition === MODAL_CLOSING_STATE.CLOSING && styles.closing
        } ${className}`,
      )}
    >
      <ul className={cleanClassName(styles['option-list'])}>
        {options?.map(({ label, value }, index) => {
          const isHovered = index === indexForSelect;
          const isSelected = (() => {
            if (selectedValue === undefined) return false;

            if (multiple && selectedValue instanceof Array) {
              return (selectedValue as TOptionValue[]).includes(value);
            }

            return selectedValue === value;
          })();

          return (
            <li key={index} className={styles['option-item-wrap']}>
              <button
                ref={(element) => {
                  optionItemRefs.current[index] = element;
                }}
                className={cleanClassName(
                  `${styles['option-item']} ${isHovered && styles.hovered} ${
                    isSelected && styles.selected
                  }`,
                )}
                type="button"
                onClick={() => {
                  if (multiple) {
                    type MultipleSelectProps = OptionProps<
                      TOptionValue,
                      true,
                      TCancelable
                    >;
                    let valuesForSelect =
                      (selectedValue as TOptionValue[]) ?? [];

                    const handleChange =
                      onChange as MultipleSelectProps['onChange'];

                    if (cancelable) {
                      valuesForSelect = isSelected
                        ? valuesForSelect?.filter(
                            (selectedValue) => selectedValue !== value,
                          )
                        : [...valuesForSelect, value];
                    }

                    handleChange?.({
                      value: valuesForSelect,
                    });
                  } else {
                    type SingleSelectProps = OptionProps<
                      TOptionValue,
                      false,
                      TCancelable
                    >;
                    const handleChange =
                      onChange as SingleSelectProps['onChange'];

                    handleChange?.({
                      value: isSelected && cancelable ? undefined : value,
                    } as SelectChangeEvent<TOptionValue, false, TCancelable>);
                  }
                }}
                onMouseEnter={() => setIndexForSelect(index)}
              >
                <div>{label}</div>
                <div
                  className={cleanClassName(
                    `${styles['icon-wrap']} ${isSelected && styles.show}`,
                  )}
                >
                  <Check size="1.2em" />
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </section>
  ) : (
    <></>
  );
};
