import styles from './Badge.module.scss';

interface BadgeProps {
  children: React.ReactNode;
  color?: 'purple' | 'yellow' | 'red' | 'primary' | 'green' | 'gray-scale';
}

export const Badge = ({ children, color = 'primary' }: BadgeProps) => {
  return <div className={`${styles.badge} ${styles[color]}`}>{children}</div>;
};
