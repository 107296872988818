import type { HTMLTagProps, Size } from '@/types';

import { useEffect, useRef, useState } from 'react';

import { cleanClassName } from '@common/utils';

import styles from './InputWrap.module.scss';

export type InputWrapProps = Omit<HTMLTagProps<'div'>, 'size'> & {
  size?: Size | 'fit-content';
  validationMessage?: string;
  message?: string;
  reversed?: boolean;
  label?: string;
  required?: boolean;
  shape?: 'rectangle' | 'capsule';
  isInvalid?: boolean;
};

/**
 *
 * @deprecated 공통 컴포넌트를 사용해주세요.
 */
export const InputWrap = ({
  //* Input.Wrap props
  size = 'large',
  validationMessage,
  message,
  reversed = false,
  label,
  required = false,
  isInvalid = false,
  shape = 'rectangle',

  //* HTML div props
  children,
  className,
  ...restDivProps
}: InputWrapProps) => {
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const [messageWrapHeight, setMessageWrapHeight] =
    useState<React.CSSProperties>();

  const hasMessage = !!(validationMessage || message);

  useEffect(() => {
    const { current } = messageContainerRef;
    if (current && hasMessage) {
      return setMessageWrapHeight({
        height: current.clientHeight,
      });
    }

    return setMessageWrapHeight(undefined);
  }, [hasMessage]);

  return (
    <div
      {...restDivProps}
      className={cleanClassName(`${styles['input-wrap']} ${className}`)}
    >
      <label className={styles.label}>
        {label ? (
          <p
            className={cleanClassName(
              `${styles['label-text']} ${required && styles.required}`,
            )}
          >
            {label}
          </p>
        ) : null}
        <div
          className={cleanClassName(
            `${styles['input-wrap-content']} ${styles[size]} ${
              reversed && styles.reversed
            } ${styles[shape]} ${(validationMessage || isInvalid) && styles.error}`,
          )}
        >
          {children}
        </div>
      </label>
      <div
        className={cleanClassName(
          `${styles['dynamic-message-height']} ${
            messageWrapHeight && styles.show
          }`,
        )}
        style={messageWrapHeight}
      >
        {hasMessage ? (
          <div
            ref={messageContainerRef}
            className={styles['message-container']}
          >
            <span className={styles['validation-message']}>
              {validationMessage}
            </span>
            <span>{message}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
