import type { HTMLTagProps } from '@/types';

import { cleanClassName } from '@common/utils';

import styles from './Accordion.module.scss';
import { AccordionContents } from './AccordionContents';
import { AccordionTitle } from './AccordionTitle';
import {
  AccordionContextProvider,
  type AccordionContextProviderProps,
} from './contexts/AccordionContext';

export type { AccordionTitleProps } from './AccordionTitle';
export type { AccordionContentsProps } from './AccordionContents';

export interface AccordionProps
  extends HTMLTagProps<'dl'>,
    Pick<AccordionContextProviderProps, 'opened' | 'size' | 'ellipsis'> {}

export const Accordion = Object.assign(
  ({
    //* Accordion props
    opened = false,
    size = 'medium',
    ellipsis = true,

    //* HTML dl props
    children,
    className,
    ...restDlProps
  }: AccordionProps) => {
    return (
      <dl
        {...restDlProps}
        className={cleanClassName(
          `${styles['accordion-container']}${className}`,
        )}
      >
        <AccordionContextProvider
          ellipsis={ellipsis}
          opened={opened}
          size={size}
        >
          {children}
        </AccordionContextProvider>
      </dl>
    );
  },
  {
    Title: AccordionTitle,
    Contents: AccordionContents,
  },
);
