'use client';

import type { Region } from '@/types';

import { useQuery } from '@tanstack/react-query';
import ky from 'ky';
import { useSearchParams } from 'next/navigation';

import { UNAUTH_API } from '@/api';
import { queryKeys } from '@/constants/query-keys';
import { useAuthStore } from '@/stores/AuthStore';

const INITIAL_REGION = '서울특별시';
const INITIAL_SUB_REGION = '관악구';
const INITIAL_CODE_REGION = 'C01-01';
const INITIAL_CODE_SUB_REGION = 'C01-01-21';

const fetchLocation = () => {
  return new Promise<GeolocationPosition>((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser'));
    } else {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    }
  });
};

export const useGeolocation = () => {
  const searchParams = useSearchParams();
  const [myInfo, authRegion] = useAuthStore((state) => [
    state.myInfo,
    state.region,
  ]);

  const codeSubRegionParam = searchParams?.get('code_sub_region');

  const { data, isError, refetch, isFetching } = useQuery({
    queryKey: [queryKeys.myGeolocation],
    queryFn: async () => {
      try {
        // 위치 정보를 가져옵니다.
        const position = await fetchLocation();
        const coords = `${position.coords.longitude},${position.coords.latitude}`;

        // 가져온 위치 정보를 바탕으로 지오코딩 API를 호출합니다.
        const geocodingResult = await UNAUTH_API.getGeocoding({ coords });

        return geocodingResult;
      } catch (error) {
        // 에러 처리 로직
        console.error('Error fetching location or geocoding:', error);
        throw error;
      }
    },
    select: ({ context }) =>
      ({
        codeRegion: context?.code_region,
        codeSubRegion: context?.code_sub_region,
        region: context?.region,
        subRegion: context?.sub_region,
      }) satisfies Region,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const { data: ipData } = useQuery({
    queryKey: [queryKeys.myGeolocation, 'ip'],
    queryFn: async () => {
      const ip = await ky.get('https://api.ip.pe.kr').text();

      return UNAUTH_API.getGeolocation({ ip });
    },
    select: ({ context }) =>
      ({
        codeRegion: context?.code_region,
        codeSubRegion: context?.code_sub_region,
        region: context?.region,
        subRegion: context?.sub_region,
      }) satisfies Region,
    refetchOnWindowFocus: false,
    enabled: !data,
  });

  //* 1. param으로 동네 판별 시 code 정보만 있어서 이름을 받아오기 위해 사용 (only param)
  const { data: allList } = useQuery({
    queryKey: ['allList'],
    queryFn: UNAUTH_API.getWelloPolicyCodeAllList,
    enabled: !!codeSubRegionParam,
  });

  const findRegionByCode = (code: string | null | undefined) =>
    allList?.context?.contents?.find((policyCode) => policyCode.code === code);

  //* 1) param 체크 -> 2)최근 본 동네 -> 3)navigation -> 4)내 동네 -> 5)ip -> 6)INITIAL
  const region =
    findRegionByCode(findRegionByCode(codeSubRegionParam)?.parent_code)
      ?.value ??
    authRegion?.at(0)?.region ??
    data?.region ??
    myInfo?.region ??
    ipData?.region ??
    INITIAL_REGION;
  const subRegion =
    findRegionByCode(codeSubRegionParam)?.value ??
    authRegion?.at(0)?.subRegion ??
    data?.subRegion ??
    myInfo?.subRegion ??
    ipData?.subRegion ??
    INITIAL_SUB_REGION;
  const codeRegion =
    findRegionByCode(findRegionByCode(codeSubRegionParam)?.parent_code)?.code ??
    authRegion?.at(0)?.codeRegion ??
    data?.codeRegion ??
    myInfo?.codeRegion ??
    ipData?.codeRegion ??
    INITIAL_CODE_REGION;
  const codeSubRegion =
    findRegionByCode(codeSubRegionParam)?.code ??
    authRegion?.at(0)?.codeSubRegion ??
    data?.codeSubRegion ??
    myInfo?.codeSubRegion ??
    ipData?.codeSubRegion ??
    INITIAL_CODE_SUB_REGION;

  return {
    location: { region, subRegion, codeRegion, codeSubRegion },
    isError,
    refetch,
    isFetching,
  };
};
