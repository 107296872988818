import { cleanClassName } from '@common/utils';

import { CustomLink } from '@/components/atom/CustomLink';
import { useGlobalStore } from '@/stores/GlobalStore';

import styles from './LinkableBox.module.scss';

interface LinkableBoxProps {
  href?: string;
  children: React.ReactNode;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  as?: keyof JSX.IntrinsicElements | React.ElementType;
}

export const LinkableBox = ({
  href,
  className,
  as: Component = 'div',
  ...restProps
}: LinkableBoxProps) => {
  if (href && href !== '#')
    return (
      <CustomLink
        {...restProps}
        className={cleanClassName(`${styles['linkable-box']} ${className}`)}
        href={href}
        onClick={() => useGlobalStore.getState().setIsGlobalLoading(true)}
      />
    );

  return <Component {...restProps} className={className} />;
};
