import { Bookmark, ThumbsUp } from 'react-feather';

import { cleanClassName } from '@common/utils';

import CommentIcon from '@/assets/svgs/comment_off.svg';
import { Button, Skeleton } from '@/components/server';

import styles from './NewsCardFooter.module.scss';

export interface NewsCardFooterProps {
  isThumbUp?: boolean;
  thumbUpCount?: number;
  commentCount?: number;
  isBookmarked?: boolean;
  onClickThumbUp?: () => void;
  onClickBookmark?: () => void;
  onClickComment?: () => void;
  isLoading?: boolean;
  className?: string;
}

export const NewsCardFooter = ({
  thumbUpCount = 0,
  commentCount = 0,
  isBookmarked,
  isThumbUp,
  onClickThumbUp,
  onClickComment,
  onClickBookmark,
  isLoading,
  className,
}: NewsCardFooterProps) => {
  return (
    <footer className={cleanClassName(`${styles.footer} ${className}`)}>
      {isLoading ? (
        <Skeleton className={`${styles['icon-skeleton']} ${styles.left}`} />
      ) : (
        <section className={styles['info-section']}>
          <Button
            className={styles['like-button']}
            theme="unset"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickThumbUp?.();
            }}
          >
            <ThumbsUp
              className={cleanClassName(
                `${styles.icon} ${isThumbUp && styles.on}`,
              )}
            />
            {thumbUpCount.toLocaleString()}
          </Button>
          <Button
            className={styles['comment-button']}
            theme="unset"
            onClick={onClickComment}
          >
            <CommentIcon className={styles.icon} />
            {commentCount.toLocaleString()}
          </Button>
        </section>
      )}
      <Button
        aria-label={isBookmarked ? '북마크 제거' : '북마크 추가'}
        className={styles['bookmark-button']}
        theme="unset"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClickBookmark?.();
        }}
      >
        {isLoading ? (
          <Skeleton className={styles['icon-skeleton']} />
        ) : (
          <Bookmark
            className={cleanClassName(
              `${styles.icon} ${isBookmarked && styles.on}`,
            )}
          />
        )}
      </Button>
    </footer>
  );
};
