import { ChevronDown } from 'react-feather';

import { cleanClassName } from '@common/utils';

import {
  type OptionProps,
  Option,
  type ValidOptionValue,
  InputWrap,
  Input,
  type InputProps,
  type InputWrapProps,
} from '@/components/client';
import { useClosableOnClickOpeningState } from '@/hooks/useClosableOnClickOpeningState';
import { useSubscribedState } from '@/hooks/useSubscribedState';

import styles from './Selectbox.module.scss';

export interface SelectboxProps<
  TValidOptionValue extends ValidOptionValue = ValidOptionValue,
  TMultiple extends boolean = false,
  TCancelable extends boolean = true,
> extends Omit<
      InputProps,
      | 'style'
      | 'className'
      | 'onChange'
      | 'value'
      | 'multiple'
      | 'type'
      | 'children'
    >,
    Pick<
      InputWrapProps,
      | 'reversed'
      | 'className'
      | 'style'
      | 'label'
      | 'validationMessage'
      | 'shape'
      | 'size'
    >,
    Pick<
      OptionProps<TValidOptionValue, TMultiple, TCancelable>,
      'options' | 'float' | 'value' | 'multiple' | 'cancelable' | 'onChange'
    > {
  children?: React.ReactNode;
}

export const Selectbox = <
  TValidOptionValue extends ValidOptionValue = ValidOptionValue,
  TMultiple extends boolean = false,
  TCancelable extends boolean = false,
>({
  //* Selectbox props
  children,

  //* InputWrap props
  reversed,
  className,
  style,
  label,
  validationMessage,
  size,
  shape,

  //* Option props
  options,
  float,
  onChange,
  value,
  multiple,
  cancelable,

  //* Input props
  onClick,
  ...restInputProps
}: SelectboxProps<TValidOptionValue, TMultiple, TCancelable>) => {
  const [selectedValue, setSelectedValue] = useSubscribedState(value);

  const { disabled, readOnly, required } = restInputProps;

  const selectedOption = multiple
    ? options?.filter(({ value }) =>
        (selectedValue as TValidOptionValue[])?.includes(value),
      )
    : options?.find(({ value }) => value === selectedValue);

  const displayedValue = Array.isArray(selectedOption)
    ? selectedOption.map(({ label }) => label).join(', ')
    : selectedOption?.label;

  const {
    openingState: [opened, setOpened],
    setClosableOnClick,
  } = useClosableOnClickOpeningState();

  const setOpenedIfEnabled: typeof setOpened = (value) => {
    if (readOnly) return;
    setOpened(value);
  };

  const decoration = children ?? (
    <ChevronDown
      className={cleanClassName(
        `${styles['selectbox-icon']} ${opened && styles.reversed} ${
          disabled && styles.disabled
        }`,
      )}
    />
  );

  return (
    <InputWrap
      className={className}
      label={label}
      required={required}
      reversed={reversed}
      shape={shape}
      size={size}
      style={style}
      validationMessage={validationMessage}
      onMouseEnter={() => setClosableOnClick(false)}
      onMouseLeave={() => setClosableOnClick(true)}
    >
      <Input
        {...restInputProps}
        type="button"
        value={displayedValue}
        onClick={(e) => {
          onClick?.(e);
          setOpenedIfEnabled((prev) => !prev);
        }}
      />
      {decoration ? (
        <div className={styles.decoration}>{decoration}</div>
      ) : null}
      <Option
        cancelable={cancelable}
        float={float}
        multiple={multiple}
        opened={opened}
        options={options}
        value={selectedValue}
        onChange={(e) => {
          onChange?.(e);
          setSelectedValue(e.value);
          setOpenedIfEnabled(false);
        }}
      />
    </InputWrap>
  );
};
