import { Button } from '@common/components';
import { cleanClassName } from '@common/utils';

import styles from './ContentsListFooter.module.scss';

export interface ContentsListFooterProps {
  className?: string;
  content?: React.ReactNode;
  niceJoinButton?: React.ReactNode;
  buttonTitle: string;
  disabled?: boolean;
  onClickContent?: () => void;
  loading?: boolean;
}

export const ContentsListFooter = ({
  className,
  content,
  niceJoinButton,
  buttonTitle,
  disabled = false,
  onClickContent,
  loading,
}: ContentsListFooterProps) => {
  return (
    <footer className={cleanClassName(`${styles.footer} ${className}`)}>
      {content ? <div className={styles.content}>{content}</div> : null}
      <Button
        disabled={disabled}
        loading={loading}
        theme="primary-fill"
        onClick={onClickContent}
      >
        {buttonTitle}
      </Button>
      {niceJoinButton}
    </footer>
  );
};
