import { CustomError, createUuid } from '@common/utils';
import { queryOptions } from '@tanstack/react-query';

import { AUTH_API, UNAUTH_API, type TERM_CATEGORY } from '@/api';

export const termsQueryOptionsKeys = {
  term: createUuid(),
  termsHistory: createUuid(),
  locationServiceConsent: createUuid(),
  privateDataConsent: createUuid(),
};

export const termsQueryOptions = {
  termsHistory: (termCategory?: TERM_CATEGORY | null) =>
    queryOptions({
      queryKey: [termsQueryOptionsKeys.termsHistory, termCategory],

      queryFn: () => {
        if (!termCategory)
          throw new CustomError({
            return_message: 'termsDrawerType가 없습니다.',
          });

        return UNAUTH_API.getTermsList({
          termCategory,
        });
      },
      enabled: !!termCategory,
    }),
  term: (termCategory: TERM_CATEGORY) =>
    queryOptions({
      queryKey: [termsQueryOptionsKeys.term, termCategory],
      queryFn: () => UNAUTH_API.getTerm({ termCategory }),
    }),
  locationServiceConsent: (isLogin?: boolean) =>
    queryOptions({
      queryKey: [termsQueryOptionsKeys.locationServiceConsent, isLogin],
      enabled: isLogin !== undefined,
      queryFn: async () => {
        const isLocationServiceConsent = isLogin
          ? ((await AUTH_API.getLocationServiceConsent()).context
              ?.location_service_consent_yn ?? false)
          : true;

        return isLocationServiceConsent;
      },
    }),
  privateDataConsent: () =>
    queryOptions({
      queryKey: [termsQueryOptionsKeys.privateDataConsent],
      queryFn: AUTH_API.getPrivateDataConsent,
      select: (data) => data.context?.private_data_consent_yn ?? false,
    }),
};
