import type { ButtonProps } from '@common/components';

import { Button } from '@common/components';
import { cn } from '@common/utils';

export interface FooterButtonProps extends ButtonProps {
  shape?: ButtonProps['roundness'];
}

export const ButtonFooter = ({
  className,
  style,
  shape,
  ...restButtonProps
}: FooterButtonProps) => {
  return (
    <footer
      className={cn(
        'sticky bottom-0 p-20 box-border mt-auto border-t border-Gray-300 bg-Gray-white',
        className,
      )}
      style={style}
    >
      <Button {...restButtonProps} className="w-full" roundness={shape} />
    </footer>
  );
};
