import type { HTMLTagProps } from '@/types';

import { forwardRef } from 'react';
import { Check } from 'react-feather';

import { cleanClassName } from '@common/utils';

import { useSubscribedState } from '@/hooks/useSubscribedState';

import styles from './Checkbox.module.scss';

export interface CheckboxProps
  extends Pick<HTMLTagProps<'div'>, 'className' | 'style'>,
    Omit<
      HTMLTagProps<'input'>,
      'size' | 'type' | 'style' | 'value' | 'children'
    > {
  children?: React.ReactNode;
  checkable?: boolean;
}

/**
 *
 * @deprecated 공통 컴포넌트를 사용해주세요.
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      //* Checkbox props
      children,
      checkable = true,

      //* HTML div props
      className,
      style,

      //* HTML input props
      checked,
      onChange,
      disabled,
      ...restInputProps
    },
    ref,
  ) => {
    const [turned, setTurned] = useSubscribedState(checked);

    return (
      <label
        className={cleanClassName(
          `${styles['checkbox-container']} ${className}`,
        )}
        style={style}
      >
        <div
          className={cleanClassName(
            `${styles.checkbox} ${disabled && styles.disabled} ${
              turned ? styles.on : styles.off
            }`,
          )}
        >
          {turned ? <Check /> : null}
        </div>
        <input
          {...restInputProps}
          ref={ref}
          readOnly
          checked={turned}
          className={styles['checkbox-input']}
          disabled={disabled}
          type="checkbox"
          onChange={(e) => {
            if (checkable) {
              onChange?.(e);

              setTurned((prev) => !prev);
            }
          }}
        />
        {children}
      </label>
    );
  },
);

Checkbox.displayName = 'Checkbox';
