import { cleanClassName } from '@common/utils';

import { CustomLink } from '@/components/atom/CustomLink';
import { LazyImage } from '@/components/client';
import {
  NewsCardFooter,
  type NewsCardFooterProps,
  Badge,
} from '@/components/server';
import { Skeleton } from '@/components/server';

import styles from './RecommendNewsCard.module.scss';

interface NewsCardProps extends NewsCardFooterProps {
  dday?: string;
  agencyLogo?: string;
  policyName?: string;
  agency?: string;
  dateText?: string;
  supportBenefit?: string;
  href?: string;
  border?: boolean;
}

export const RecommendNewsCard = ({
  dday,
  border = false,
  agencyLogo,
  policyName,
  agency,
  dateText,
  supportBenefit,
  href = '#',
  isLoading,

  ...restProps
}: NewsCardProps) => {
  return (
    <article
      className={cleanClassName(
        `${styles['policy-card']} ${border && styles['recommend-news-card']}`,
      )}
    >
      {isLoading ? (
        <div className={styles['card-area']}>
          <Skeleton className={styles['badge-skeleton']} />
          <div className={styles.link}>
            <Skeleton className={styles.thumbnail} />
            <div className={styles['title-skeleton-container']}>
              <Skeleton className={styles['title-skeleton']} />
              <Skeleton className={styles['title-skeleton']} />
            </div>
          </div>
          <div className={styles['policy-info']}>
            <Skeleton className={styles['date-text-skeleton']} />
            <Skeleton className={styles['date-text-skeleton']} />
            <Skeleton className={styles['date-text-skeleton']} />
          </div>
        </div>
      ) : (
        <>
          <CustomLink className={styles['card-area']} href={href}>
            {isLoading ? (
              <Skeleton className={styles['badge-skeleton']} />
            ) : (
              <Badge color="red">{dday}</Badge>
            )}
            <div className={styles.link}>
              <LazyImage
                alt={`policy-card-${policyName}`}
                className={styles.thumbnail}
                src={agencyLogo}
              />
              <h3 className={styles.title}>{policyName}</h3>
            </div>
            <div className={styles['policy-info']}>
              <span className={styles['text-ellipsis']}>{agency}</span>
              {dateText ? (
                <span className={styles['text-ellipsis']}>{dateText}</span>
              ) : null}
              {supportBenefit ? (
                <span className={styles['text-ellipsis']}>
                  {supportBenefit}
                </span>
              ) : null}
            </div>
          </CustomLink>
        </>
      )}
      <NewsCardFooter {...restProps} isLoading={isLoading} />
    </article>
  );
};
