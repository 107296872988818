import type { WelloPolicyMetaData } from '@/query-factory/filter';
import type { ReactScrollOptions } from '@/types';

import {
  Fragment,
  useCallback,
  useEffect,
  useId,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { RotateCw, Users } from 'react-feather';
import { useForm } from 'react-hook-form';
import { scroller } from 'react-scroll';

import {
  Button,
  InputDecorator,
  Input,
  Chip,
  BottomDrawer,
} from '@common/components';
import { useToast } from '@common/hooks';
import { CustomError, createYupSchemaResolver, customYup } from '@common/utils';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { isEqual, uniq } from 'es-toolkit';

import {
  BottomNavPortal,
  Tooltip,
  RegionSelect,
  LazyImage,
} from '@/components/client';
import { SELECTOR, STORAGE_KEY } from '@/constants';
import {
  EXCEPTED_CODE,
  EXCEPTED_HIDDEN_CODE,
  filterQueryOptions,
} from '@/query-factory/filter';

enum HELP_MODAL {
  FAMILY_COUNT = 'familyCount',
  FAMILY_INCOME = 'familyIncome',
}

interface FormValues {
  filterValues: WelloPolicyMetaData;
  familyCount: number;
  familyIncome: number;
  unemploymentDate?: string;
}

interface CustomPolicyFilterFormProps {
  initialValues?: Partial<FormValues>;
  onSubmit?: (data: FormValues) => void;
  isSubmitting?: boolean;
  submitButtonLabel?: string;
  resetButton?: boolean;
  genderFilter?: boolean;
  children?: React.ReactNode;
}

export const PolicyFitFilterForm = ({
  initialValues,
  onSubmit,
  isSubmitting,
  submitButtonLabel,
  resetButton,
  children,
  genderFilter,
}: CustomPolicyFilterFormProps) => {
  const { data } = useQuery(filterQueryOptions.policyFitMetaData());

  const rootMetaDataMap = new Map<string, WelloPolicyMetaData[number]>();

  const [selectedMetaDataMap, setSelectedMetaDataMap] = useState(
    new Map<string, WelloPolicyMetaData[number]>(),
  );

  const selectedCodeList = [...selectedMetaDataMap.keys()];

  const selectedRegionCodeList = selectedCodeList.filter((code) =>
    code.includes(EXCEPTED_CODE.REGION),
  );

  let selectedMainRegionCode = '';
  let selectedSubRegionCode = '';

  selectedRegionCodeList.forEach((code) => {
    if (code.split('-').length === 2) {
      selectedMainRegionCode = code;
    } else {
      selectedSubRegionCode = code;
    }
  });

  //! ⚠️ 상태 값으로 관리할 필요가 없는 값
  const subTitleCodeList: string[] = [];
  const prevSubTitleCodeList = useRef(subTitleCodeList);

  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const newSubTitleCodeList = subTitleCodeList.filter(
      (code) => !prevSubTitleCodeList.current.includes(code),
    );

    const scrollTo = newSubTitleCodeList[newSubTitleCodeList.length - 1];

    //! 애니메이션 후 스크롤
    if (scrollTo) {
      const scrollTimmer = setTimeout(() => {
        if (!isClicked) return;

        scroller.scrollTo(scrollTo, {
          smooth: true,
          offset: -100,
          containerId: SELECTOR.MAIN,
        });
      }, 300);

      return () => {
        clearTimeout(scrollTimmer);
        prevSubTitleCodeList.current = subTitleCodeList;
      };
    }

    return () => {
      prevSubTitleCodeList.current = subTitleCodeList;
    };
  });

  const {
    register,
    getValues,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<Omit<FormValues, 'filterValues'>>({
    resolver: createYupSchemaResolver<Omit<FormValues, 'filterValues'>>(
      (yup) => ({
        familyCount: yup
          .number()
          .required('가구원 수를 입력해 주세요.')
          .integer('정수만 입력해 주세요.')
          .required('가구원 수를 입력해 주세요.')
          .min(1, '1 이상의 숫자만 입력해 주세요.')
          .max(99, '99 이하의 숫자만 입력해 주세요.'),
        familyIncome: yup
          .number()
          .required('가구 월소득을 입력해 주세요.')
          .integer('정수만 입력해 주세요.')
          .max(9999, '1억 이하의 숫자만 입력해 주세요.'),
        unemploymentDate: selectedMetaDataMap.has(EXCEPTED_CODE.UNEMPLOYMENT)
          ? customYup
              .stringDate({
                onlyPast: true,
              })
              .required('실직일을 입력해 주세요')
          : yup.string().nullable(),
      }),
    ),
    mode: 'onBlur',
    resetOptions: {
      keepDefaultValues: true,
    },
  });

  const resetAll = useCallback(() => {
    let baseFormValues: Partial<FormValues> | undefined = initialValues;

    if (!baseFormValues) {
      const sessionStorageData = sessionStorage.getItem(
        STORAGE_KEY.POLICY_FILTER,
      );

      if (sessionStorageData) baseFormValues = JSON.parse(sessionStorageData);
    }

    if (baseFormValues) {
      const { filterValues, familyIncome, ...restinitialValues } =
        baseFormValues;

      setSelectedMetaDataMap(
        new Map(
          filterValues?.map((metaData) => {
            if (!metaData.code) throw new Error('metaData.code가 없습니다.');

            return [metaData.code, metaData];
          }),
        ),
      );

      reset({
        ...restinitialValues,
        familyIncome: familyIncome ? familyIncome / 10000 : undefined,
      });
    }
  }, [initialValues, reset]);

  useLayoutEffect(() => resetAll(), [resetAll]);

  const [openedHelpModal, setOpenedHelpModal] = useState<HELP_MODAL>();

  const closeModal = () => setOpenedHelpModal(undefined);

  const applySelectedMap = () =>
    setSelectedMetaDataMap(
      (selectedMetaDataMap) => new Map(selectedMetaDataMap),
    );

  const { toast } = useToast();

  const formId = useId();

  if (!data) return null;

  const { customPolicyMetaDataList, genderMetaData } = data;

  return (
    <form
      className="relative"
      id={formId}
      onSubmit={handleSubmit(
        ({ familyIncome, familyCount, unemploymentDate }) => {
          const formValues = {
            familyCount,
            unemploymentDate: unemploymentDate
              ? dayjs(unemploymentDate).format('YYYY-MM-DD')
              : undefined,
            filterValues: [...selectedMetaDataMap.values()],
            familyIncome: familyIncome * 10000,
          };

          if (
            genderFilter &&
            !selectedMetaDataMap.has(`${EXCEPTED_CODE.GENDER}-01`) &&
            !selectedMetaDataMap.has(`${EXCEPTED_CODE.GENDER}-02`)
          ) {
            scroller.scrollTo(EXCEPTED_CODE.GENDER, {
              smooth: true,
              offset: -100,
              containerId: SELECTOR.MAIN,
            } satisfies ReactScrollOptions);

            return toast({
              message: '성별을 선택해주세요.',
              type: 'fail',
            });
          }

          sessionStorage.setItem(
            STORAGE_KEY.POLICY_FILTER,
            JSON.stringify(formValues),
          );

          onSubmit?.(formValues);
        },
        (error) => {
          for (const key in error) {
            const message = error[key as keyof typeof error]?.message;

            if (message) {
              scroller.scrollTo(key, {
                smooth: true,
                offset: -100,
                containerId: SELECTOR.MAIN,
              } satisfies ReactScrollOptions);

              return toast({
                message,
                type: 'fail',
              });
            }
          }
        },
      )}
    >
      <section className="px-20">
        <RegionSelect
          id={EXCEPTED_CODE.REGION}
          value={{
            mainRegionCode: selectedMainRegionCode,
            subRegionCode: selectedSubRegionCode,
          }}
          onChange={({ mainRegion, subRegion }) => {
            selectedMetaDataMap.forEach(({ code }) => {
              if (code?.startsWith(EXCEPTED_CODE.REGION))
                selectedMetaDataMap.delete(code);
            });

            if (!mainRegion.code || !subRegion.code)
              throw new CustomError({
                return_message: '지역 데이터가 올바르지 않습니다.',
              });

            selectedMetaDataMap.set(mainRegion.code, mainRegion);
            selectedMetaDataMap.set(subRegion.code, subRegion);

            applySelectedMap();
          }}
        />
        <p className="typo-caption1 font-400 mt-10">
          지역 설정은{' '}
          <span className="text-B-500">{`맞춤정책 > 맞춤정보 수정 > 지역`}</span>
          에서 언제든지 수정할 수 있어요
        </p>
      </section>
      <dl className="flex flex-wrap gap-6 items-start justify-start m-0 px-20">
        {genderFilter ? (
          <>
            <dt
              className="w-full typo-body4 flex items-center text-Gray-800 mt-26 mb-4"
              id={EXCEPTED_CODE.GENDER}
            >
              성별
            </dt>
            <dd className="gap-8 flex w-full">
              {genderMetaData.map((meta, index) => {
                const { code, value } = meta;

                if (!code) return null;

                return (
                  <Button
                    key={index}
                    className="flex-1"
                    dimensions="H48"
                    theme={
                      selectedMetaDataMap.has(code)
                        ? 'primary-fill'
                        : 'sub-line'
                    }
                    onClick={() => {
                      const checked = !selectedMetaDataMap.has(code);

                      if (checked) {
                        selectedCodeList
                          .filter((code) => code.includes(EXCEPTED_CODE.GENDER))
                          .forEach((code) => selectedMetaDataMap.delete(code));

                        selectedMetaDataMap.set(code, meta);
                        applySelectedMap();
                      } else {
                        selectedMetaDataMap.delete(code);
                        applySelectedMap();
                      }
                    }}
                  >
                    {value}
                  </Button>
                );
              })}
            </dd>
          </>
        ) : null}
        {customPolicyMetaDataList?.map((metaData, index) => {
          const { code, value, level, duplicate_avail_yn, parent_code } =
            metaData;

          if (!code) return null;

          switch (level) {
            case 1: {
              rootMetaDataMap.set(code, metaData);

              const defaultCase = (
                <dt
                  className="w-full typo-body4 flex items-center text-Gray-800 mt-26 mb-4 gap-4"
                  id={code}
                >
                  {metaData.image_url ? (
                    <LazyImage
                      height={18}
                      src={metaData.image_url}
                      width={18}
                    />
                  ) : null}
                  {value}
                  {duplicate_avail_yn ? (
                    <span className="text-Gray-600">(중복선택 가능)</span>
                  ) : null}
                </dt>
              );

              switch (code) {
                case 'C05':
                  return (
                    <Fragment key={index}>
                      {selectedCodeList.includes(EXCEPTED_CODE.UNEMPLOYMENT) ? (
                        <>
                          <dt className="flex gap-4 animate-fade-up items-center duration-300 typo-body4 text-Gray-800 w-full mt-26 mb-4">
                            실직일
                            <Tooltip>
                              <Tooltip.Area>
                                <div className="flex items-center justify-center w-16 h-16 border-1 border-Gray-500 rounded-pill text-Gray-700 font-700 typo-caption1">
                                  ?
                                </div>
                              </Tooltip.Area>
                              <Tooltip.Content align="start">
                                4대보험 기준 실직 시작일
                                <br />
                                취업 경험이 없는 경우 성년(만19세)이 된 시점
                              </Tooltip.Content>
                            </Tooltip>
                          </dt>
                          <dd className="w-full animate-fade-up duration-300">
                            <InputDecorator
                              validationMessage={
                                errors.unemploymentDate?.message
                              }
                            >
                              <Input
                                {...register('unemploymentDate')}
                                placeholder="실직일자를 입력해주세요(YYYYMMDD)"
                                type="text-date"
                              />
                            </InputDecorator>
                          </dd>
                        </>
                      ) : null}
                      {defaultCase}
                    </Fragment>
                  );
                case 'C06':
                  return (
                    <Fragment key={index}>
                      <dt className="w-full typo-body4 flex items-center text-Gray-800 mt-26 mb-4">
                        가구원 수
                      </dt>
                      <dd className="w-full">
                        <InputDecorator
                          validationMessage={errors.familyCount?.message}
                        >
                          <Input
                            {...register('familyCount')}
                            max={99}
                            placeholder="가구원 수를 입력해 주세요"
                            rightContent="명"
                            type="number"
                          />
                        </InputDecorator>
                        <button
                          className="mt-4 flex items-center gap-4 border-none text-Gray-600 typo-caption1"
                          type="button"
                          onClick={() =>
                            setOpenedHelpModal(HELP_MODAL.FAMILY_COUNT)
                          }
                        >
                          <div className="flex items-center justify-center w-16 h-16 border-1 border-Gray-500 rounded-pill text-Gray-700 font-700">
                            ?
                          </div>
                          가구원수 기준을 모르겠어요.
                        </button>
                      </dd>
                      <dt className="w-full typo-body4 flex items-center text-Gray-800 mt-26 mb-4">
                        가구 월소득
                      </dt>
                      <dd className="w-full">
                        <InputDecorator
                          validationMessage={errors.familyIncome?.message}
                        >
                          <Input
                            {...register('familyIncome')}
                            maxLength={4}
                            placeholder="가구 월소득을 입력해 주세요"
                            rightContent="만원"
                            type="local-string-number"
                          />
                        </InputDecorator>
                        <button
                          className="mt-4 flex items-center gap-4 border-none text-Gray-600 typo-caption1"
                          type="button"
                          onClick={() =>
                            setOpenedHelpModal(HELP_MODAL.FAMILY_INCOME)
                          }
                        >
                          <div className="flex items-center justify-center w-16 h-16 border-1 border-Gray-500 rounded-pill text-Gray-700 font-700 typo-caption1">
                            ?
                          </div>
                          가구 월소득은 가구원의 월소득을 모두 합산한
                          금액입니다.
                        </button>
                      </dd>
                      <BottomDrawer
                        opened={openedHelpModal === HELP_MODAL.FAMILY_COUNT}
                        renderTo={() =>
                          document.getElementById(SELECTOR.MAIN_WRAPPER)
                        }
                        onClose={closeModal}
                      >
                        <BottomDrawer.HandleWrapper>
                          <BottomDrawer.Handle />
                        </BottomDrawer.HandleWrapper>
                        <BottomDrawer.Contents className="flex-col flex gap-10 items-center pb-16">
                          <Users className="w-42 h-42 text-B-500" />
                          <h4 className="typo-body1 font-600">가구원 수란?</h4>
                          <section className="text-center word-break-keep typo-body3">
                            한 가구에서 함께 살고 있는 구성원 수를 계산합니다.
                            <br />
                            주민등록 여부와 관계 없이 같이 살고 있을 경우 <br />
                            (예 : 친구)에도 포함됩니다.
                            <br />
                            <span className="text-B-500 font-600">
                              1인 가구의 경우 &apos;1명&apos;
                            </span>
                            으로 작성해주세요!
                          </section>
                        </BottomDrawer.Contents>
                      </BottomDrawer>
                      <BottomDrawer
                        opened={openedHelpModal === HELP_MODAL.FAMILY_INCOME}
                        onClose={closeModal}
                      >
                        <BottomDrawer.HandleWrapper>
                          <BottomDrawer.Handle />
                        </BottomDrawer.HandleWrapper>
                        <BottomDrawer.Contents className="flex-col flex gap-10 items-center pb-16">
                          <Users className="w-42 h-42 text-B-500" />
                          <h4 className="typo-body1 font-600">
                            가구 월소득이란?
                          </h4>
                          <section className="text-center word-break-keep typo-body3">
                            가구 모든 구성원의 근로소득, 건강보험료 납입고지서
                            등으로 확인되는 최근 6개월 간의 평균소득을
                            의미합니다.
                          </section>
                          <section className="text-center word-break-keep typo-body3">
                            가구의 월 소득을 정확하게 입력하기 어려우시다면
                          </section>
                          <section className="text-center word-break-keep typo-body3">
                            <span className="text-B-500 font-600">
                              [본인소득 + (200만원 X 본인을 제외한 소득이 있는
                              가구원 수)]
                            </span>
                            를 입력해 주세요
                          </section>
                          <p className="typo-caption1">
                            · 200만원은 2023년 최저임금 기준입니다.
                          </p>
                        </BottomDrawer.Contents>
                      </BottomDrawer>
                      {defaultCase}
                    </Fragment>
                  );

                default:
                  return <Fragment key={index}>{defaultCase}</Fragment>;
              }
            }

            case 2: {
              if (!parent_code) return null;

              const rootMetaData = rootMetaDataMap.get(parent_code);

              if (!rootMetaData) return null;

              const isDuplicatable = rootMetaData.duplicate_avail_yn;

              return (
                <dd key={index} className="w-fit m-0">
                  <Chip
                    checked={selectedMetaDataMap.has(code)}
                    className="py-5 px-10 typo-body4"
                    onCheckedChange={(checked) => {
                      //* 최신 선택된 코드 리스트를 가져오기 위한 함수
                      const getSelectedCodeList = () => [
                        ...selectedMetaDataMap.keys(),
                      ];

                      if (checked) {
                        if (isDuplicatable) {
                          selectedMetaDataMap.set(code, metaData);
                        } else {
                          const duplicatedCode = getSelectedCodeList().filter(
                            (code) => code.includes(parent_code),
                          );

                          duplicatedCode.forEach((code) => {
                            selectedMetaDataMap.delete(code);
                          });

                          selectedMetaDataMap.set(code, metaData);
                        }
                      } else {
                        getSelectedCodeList().forEach((selectedCode) => {
                          if (selectedCode.includes(code))
                            selectedMetaDataMap.delete(selectedCode);
                        });
                      }

                      //* 해당사항 없음에 대한 예외처리
                      if (code.includes(EXCEPTED_CODE.TARGET_CHARACTERISTICS)) {
                        const isTargetEmpty =
                          code ===
                          EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY;

                        if (checked) {
                          //* 해당 사항 없음 선택시 다른 선택된 대상 특성 삭제
                          if (isTargetEmpty) {
                            getSelectedCodeList().forEach((code) => {
                              if (
                                code.includes(
                                  EXCEPTED_CODE.TARGET_CHARACTERISTICS,
                                ) &&
                                code !==
                                  EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY
                              )
                                selectedMetaDataMap.delete(code);
                            });
                          } //* 다른 대상 특성 선택시 해당 사항 없음 삭제
                          else {
                            selectedMetaDataMap.delete(
                              EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY,
                            );
                          }
                        } //* 대상 특성에 아무것도 선택하지 않았을 때 해당 사항 없음 추가
                        else {
                          const hasSelectedTargetCharacteristics =
                            getSelectedCodeList().some((code) =>
                              code.includes(
                                EXCEPTED_CODE.TARGET_CHARACTERISTICS,
                              ),
                            );

                          if (!hasSelectedTargetCharacteristics) {
                            const targetCharacteristicsEmptyMetaData =
                              customPolicyMetaDataList.find(
                                ({ code }) =>
                                  code ===
                                  EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY,
                              );

                            if (!targetCharacteristicsEmptyMetaData)
                              throw new Error(
                                'meta data에 대상 특성 해당 없음이 없습니다.',
                              );

                            selectedMetaDataMap.set(
                              EXCEPTED_HIDDEN_CODE.TARGET_CHARACTERISTICS_EMPTY,
                              targetCharacteristicsEmptyMetaData,
                            );
                          }
                        }
                      }

                      applySelectedMap();
                    }}
                    onClick={() => {
                      setIsClicked(true);
                    }}
                  >
                    {value}
                  </Chip>
                </dd>
              );
            }

            default: {
              if (!parent_code) return null;

              const hasSelectedParentMetaData =
                selectedMetaDataMap.has(parent_code);

              if (!hasSelectedParentMetaData) return null;

              //! ⚠️ 서버에서 받아온 선택했던 값들은 메타 데이터 값의 정보들이 완벽하지 않음 (ex. duplicate_avail_yn가 모두 false로 나오는 등)
              const parentMetaData = selectedMetaDataMap.get(parent_code);

              if (!parentMetaData) return null;

              const isFirstChild = !subTitleCodeList.includes(parent_code);

              const isDuplicatable = parentMetaData.duplicate_avail_yn;

              if (isFirstChild) {
                subTitleCodeList.push(parent_code);
              }

              return (
                <Fragment key={index}>
                  {isFirstChild ? (
                    <dt
                      className="w-full mt-26 mb-4 typo-body4 text-Gray-800 animate-fade-up duration-300"
                      id={parent_code}
                    >
                      {parentMetaData.value}
                      {isDuplicatable ? <span>(중복선택 가능)</span> : null}
                    </dt>
                  ) : null}
                  <dd className="animate-fade-up duration-300 w-fit m-0">
                    <Chip
                      checked={selectedMetaDataMap.has(code)}
                      className="py-5 px-10 typo-body4"
                      onChange={(e) => {
                        const { checked } = e.target;

                        if (checked) {
                          if (isDuplicatable) {
                            selectedMetaDataMap.set(code, metaData);
                          } else {
                            const duplicatedCode = selectedCodeList.filter(
                              (code) =>
                                code !== parent_code &&
                                code.includes(parent_code),
                            );

                            duplicatedCode.forEach((code) => {
                              selectedMetaDataMap.delete(code);
                            });

                            selectedMetaDataMap.set(code, metaData);
                          }
                        } else {
                          selectedCodeList.forEach((selectedCode) => {
                            if (selectedCode.includes(code))
                              selectedMetaDataMap.delete(selectedCode);
                          });
                        }

                        applySelectedMap();
                      }}
                    >
                      {value}
                    </Chip>
                  </dd>
                </Fragment>
              );
            }
          }
        })}
      </dl>
      <BottomNavPortal hasShadow={false}>
        <footer className="flex p-20 w-full gap-8">
          {children}
          {resetButton ? (
            <Button
              theme="sub-text"
              onClick={() => {
                const NewInitialValues = {
                  ...initialValues,
                  familyIncome: initialValues?.familyIncome
                    ? initialValues?.familyIncome / 10000
                    : undefined,
                };

                const currentValue = {
                  ...getValues(),
                  filterValues: [...selectedMetaDataMap.values()],
                };

                if (isEqual(NewInitialValues, currentValue)) {
                  toast({
                    message: '변경사항이 없어요',
                  });

                  return;
                }
                resetAll();
                toast({
                  message: '초기화되었습니다.',
                });
              }}
            >
              <RotateCw height="1em" width="1em" />
              초기화
            </Button>
          ) : null}
          <Button
            className="flex-1"
            disabled={(() => {
              if (!selectedMainRegionCode || !selectedSubRegionCode)
                return true;

              const selectedParentCodeOfCodeList: string[] = [];

              selectedMetaDataMap.forEach((metaData) => {
                const { parent_code } = metaData;
                if (parent_code) selectedParentCodeOfCodeList.push(parent_code);
              });

              //* 선택안한 2차 메타데이터 확인
              for (const [rootMetaCode] of rootMetaDataMap.entries())
                if (!selectedParentCodeOfCodeList.includes(rootMetaCode))
                  return true;

              //* 선택안한 3차 이상 메타데이터 확인
              const tempHasChildrenSubMetaCodeList: string[] = [];

              customPolicyMetaDataList?.forEach(({ level, parent_code }) => {
                if (2 < (level ?? 0) && parent_code)
                  tempHasChildrenSubMetaCodeList.push(parent_code);
              });

              const hasChildrenSubMetaCodeList = uniq(
                tempHasChildrenSubMetaCodeList,
              );

              for (const [code, metaCode] of selectedMetaDataMap.entries()) {
                if ((metaCode.level ?? 0) < 2) continue;

                const hasChildren = hasChildrenSubMetaCodeList.includes(code);

                if (!hasChildren) continue;

                const isChildrenSelected =
                  selectedParentCodeOfCodeList.includes(code);

                if (!isChildrenSelected) return true;
              }

              return false;
            })()}
            form={formId}
            loading={isSubmitting}
            type="submit"
          >
            {submitButtonLabel}
          </Button>
        </footer>
      </BottomNavPortal>
    </form>
  );
};
