import { Button } from '@common/components';

import { ROUTES } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { useGeolocation } from '@/hooks/useGeolocation';

import styles from './ListPageHeader.module.scss';

interface ListPageHeaderProps {
  nickName?: string;
  title: '동네소식' | '정책소식';
  children?: React.ReactNode;
}

export const ListPageHeader = ({ nickName, title }: ListPageHeaderProps) => {
  const [prefixMessage, suffixMessage] = serviceMessages[title];
  const router = useCustomRouter();
  const { location } = useGeolocation();

  return (
    <header className={styles.header}>
      <h2 className={styles.title}>
        {(nickName && title) === '정책소식' ? (
          <>
            {`${nickName}${prefixMessage}`} <br />
          </>
        ) : null}
        {title === '동네소식' ? (
          <>
            <span>
              {location.subRegion?.includes('전체') ? null : (
                <>{location.region} </>
              )}
              {location.subRegion}
            </span>
            의 <br />
          </>
        ) : null}
        {title === '정책소식' ? <span>새로운 {title}</span> : title}
        {suffixMessage}
      </h2>
      {title === '동네소식' ? (
        <Button
          dimensions="H32"
          onClick={() => router.push(ROUTES.HOMETOWN_NEWS_SEARCH.pathname)}
        >
          지역 수정
        </Button>
      ) : null}
    </header>
  );
};

type ServiceMessages = {
  [key in ListPageHeaderProps['title']]: [string, string];
};

const serviceMessages: ServiceMessages = {
  동네소식: ['님이 선택한', '을 전달해 드려요!'],
  정책소식: ['님을 위한', '을 전달해 드려요!'],
};
