'use client';

import { BottomDrawer, Button } from '@common/components';
import { useMutation, useQuery } from '@tanstack/react-query';

import { AUTH_API, TERM_CATEGORY } from '@/api';
import { ROUTES, SELECTOR, STORAGE_KEY } from '@/constants';
import { useCustomRouter } from '@/hooks/useCustomRouter';
import { authQueryOptionsKeys } from '@/query-factory';
import {
  termsQueryOptions,
  termsQueryOptionsKeys,
} from '@/query-factory/terms';
import { useGlobalStore } from '@/stores/GlobalStore';

import { LoadingPageTemplate } from './LoadingPageTemplate';

export const PolicyFilterBottomDrawer = () => {
  const setTermsDrawerType = useGlobalStore(
    (state) => state.setTermsDrawerType,
  );

  const privateDataConsentQuery = useQuery(
    termsQueryOptions.privateDataConsent(),
  );

  const privateDataConsentMutation = useMutation({
    meta: {
      invalidates: [
        [termsQueryOptionsKeys.privateDataConsent],
        [authQueryOptionsKeys.myInfo],
      ],
    },
    mutationFn: (isAgree: boolean) =>
      AUTH_API.updatePrivateDataConsent({
        requestBody: {
          private_data_consent_yn: isAgree,
        },
      }),
  });

  const router = useCustomRouter();

  const isLoading =
    privateDataConsentMutation.isPending || privateDataConsentQuery.isLoading;

  return privateDataConsentQuery.isFetched ? (
    <BottomDrawer
      opened={!(privateDataConsentQuery.data ?? true)}
      renderTo={() => document.getElementById(SELECTOR.MAIN_WRAPPER)}
    >
      <BottomDrawer.HandleWrapper>
        <BottomDrawer.Handle />
      </BottomDrawer.HandleWrapper>
      <BottomDrawer.Contents className="flex flex-col pb-16">
        <h2 className="typo-body2 font-700 text-Gray-900 mb-8">
          정책 추천을 받기위해 동의가 필요해요
        </h2>
        <p className="text-Gray-700 typo-body4 mb-16">
          동의하지 않으면 맞춤정보를 추천받을 수 없어요.
        </p>
        <p className="typo-body4 text-Gray-black mb-28">
          <span className="font-500">(필수)</span> 개인정보 수집 및 이용 동의
          <button
            className="font-600 underline ml-4"
            type="button"
            onClick={() =>
              setTermsDrawerType(TERM_CATEGORY.FIT_PRIVACY_USE_POLICY_SELECT)
            }
          >
            보기
          </button>
        </p>
        <Button
          loading={isLoading}
          onClick={() => {
            privateDataConsentMutation.mutate(true);
          }}
        >
          동의하고 넘어가기
        </Button>
        <Button
          loading={isLoading}
          theme="sub-text"
          onClick={async () => {
            await privateDataConsentMutation.mutateAsync(false);

            const policyFilterLandingFrom = sessionStorage.getItem(
              STORAGE_KEY.POLICY_FILTER_LANDING_FROM,
            );

            sessionStorage.removeItem(STORAGE_KEY.POLICY_FILTER_LANDING_FROM);

            router.push(policyFilterLandingFrom || ROUTES.HOME.pathname);
          }}
        >
          동의하지 않기
        </Button>
      </BottomDrawer.Contents>
    </BottomDrawer>
  ) : (
    <LoadingPageTemplate />
  );
};
