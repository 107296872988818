import type { PortalProps } from '@common/components';

import { Portal, Slot } from '@common/components';
import { cn } from '@common/utils';

import { SELECTOR } from '@/constants';

interface BottomNavPortalProps
  extends Pick<PortalProps, 'onLoaded' | 'children'> {
  hasShadow?: boolean;
}

export const BottomNavPortal = ({
  children,
  onLoaded,
  hasShadow = true,
}: BottomNavPortalProps) => {
  return (
    <Portal
      renderTo={() => document.getElementById(SELECTOR.GLOBAL_NAVIGATION)}
      onLoaded={onLoaded}
    >
      <Slot
        className={cn({
          'shadow-large': hasShadow,
        })}
      >
        {children}
      </Slot>
    </Portal>
  );
};
