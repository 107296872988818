import type { HTMLTagProps } from '@/types';

import { cleanClassName } from '@common/utils';

import { useSubscribedState } from '@/hooks/useSubscribedState';

import styles from './Switch.module.scss';

export interface SwitchProps
  extends Pick<HTMLTagProps<'div'>, 'className' | 'style'>,
    Omit<
      HTMLTagProps<'input'>,
      'size' | 'type' | 'style' | 'value' | 'children'
    > {
  clickable?: boolean;
  children?: React.ReactNode;
}

/**
 *
 * @deprecated 공통 컴포넌트를 사용해주세요.
 */
export const Switch = ({
  //* Switch props
  children,

  //* HTML div props
  className,
  style,

  //* HTML input props
  checked,
  onChange,
  disabled,
  clickable = true,
  ...restInputProps
}: SwitchProps) => {
  const [turned, setTurned] = useSubscribedState(checked);

  const turnedClassName = turned ? styles.on : styles.off;

  return (
    <div
      className={cleanClassName(
        `${styles['switch-content']} ${turnedClassName} ${
          disabled && styles.disabled
        } ${className}`,
      )}
      style={style}
    >
      <div className={`${styles['switch-ball']} ${turnedClassName}`}>
        {children}
      </div>
      <input
        {...restInputProps}
        checked={turned}
        className={styles['switch-input']}
        disabled={disabled}
        type="checkbox"
        onChange={(e) => {
          if (!clickable) {
            e.preventDefault();

            return;
          }

          onChange?.(e);

          setTurned(e.target.checked);
        }}
      />
    </div>
  );
};
